import React from "react";
import SuperAdminChat from "../Interfaces/SuperAdmin/Chat";
import SuperAdminSupportChat from "../Interfaces/SuperAdmin/Chat/supportChat"
import { useParams } from 'react-router-dom';

const ChatHOC = (props) => {
    const { id } = useParams()
   
        if (id) {
            return <SuperAdminSupportChat {...props} />
        } else {
            return <SuperAdminChat {...props} />
        }
 

}


export default ChatHOC