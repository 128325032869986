import React, { useState } from 'react'
import { InputGroup, Form, Button } from 'react-bootstrap';
import search from '../../../../assets/images/icons/search.png';
import { Link } from 'react-router-dom'
import { useRef } from 'react';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import Fuse from 'fuse.js';

const TopSearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const ref = useRef();
    useOnClickOutside(ref, () => onClickHandler());

    const searchArray = [
        { name: "dasboard", label: "Dashboard", redirection: "dashboard" },
        { name: "in_house_staff_sub_admin", label: "In-house Staff ( Sub Admins )", redirection: "staffs" },
        { name: "in_house_staff_add_sub_admin", label: "In-house Staff ( Add Sub Admins )", redirection: "staffs/sub-admin/create?tab=sub_admins&page=" },
        { name: "in_house_staff_interpreters", label: "In-house Staff ( Interpreters )", redirection: "staffs?tab=interpreters" },
        { name: "in_house_staff_add_interpreters", label: "In-house Staff ( Add Interpreters )", redirection: "staffs/interpreter/create?tab=interpreters&page=" },
        { name: "roles", label: "Roles", redirection: "roles" },
        { name: "add_roles", label: "Roles( Add Roles )", redirection: "roles/create" },
        { name: "packages", label: "Packages", redirection: "packages" },
        { name: "add_packages", label: "Packages( Add Packages )", redirection: "packages/create" },
        { name: "school_type", label: "school-type", redirection: "school-type" },
        { name: "add_schooltype", label: "school-type( Add school-type )", redirection: "school-type/create" },
        { name: "organizations", label: "Organizations", redirection: "organizations" },
        { name: "interpretations", label: "Interpretations", redirection: "interpretations" },
        { name: "interpretations_in_progress", label: "Interpretations( In Progress )", redirection: "interpretations?tab=progress" },
        { name: "interpretations_under_review", label: "Interpretations( Under Review )", redirection: "interpretations?tab=review" },
        { name: "interpretations_completed", label: "Interpretations( Completed )", redirection: "interpretations?tab=completed" },
        { name: "payments", label: "Payments", redirection: "payments" },
        { name: "support", label: "Support", redirection: "support" },
        { name: "support_new_ticket", label: "Support( New Ticket )", redirection: "support/new-ticket" },
        { name: "chat", label: "Chat", redirection: "chat" },
    ]

    const options = {
        keys: ['label'],
        includeScore: true,
        threshold: 0.4, // Adjust the threshold for fuzziness
        distance: 100, // Allow for small spelling mistakes
    };
    const fuse = new Fuse(searchArray, options);

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);

        const fuzzyResults = fuse.search(searchTerm);

        const filteredResults = fuzzyResults.map((result) => result.item);

        setSearchResults(filteredResults);
    };

    const onClickHandler = () => {
        setSearchTerm('')
        setSearchResults([])
    }

    return (
        <div>
            <InputGroup className="">
                <Form.Control
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    autoComplete="off"
                />
                <Button variant="outline-secondary" id="button-addon2">
                    <img src={search} alt="Search" />
                </Button>
            </InputGroup>
            <ul className='search-table' ref={ref}>
                {searchResults.map((result) => (
                    <li key={result.name} className='search-list' onClick={onClickHandler}>
                        <Link to={result.redirection}>{result.label}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TopSearchBar