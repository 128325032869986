let initialState = {}

const authenticateReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'authenticate':
            return {
                ...state,
                ...action.payload
            }
        default: return state
    }
}

export default authenticateReducer