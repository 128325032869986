import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { authenticate } from "../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../config/variables';
import { GET } from '../utils/axios.util';

const useAuthenticate = () => {

    const [ user, setUser ] = useState({});
    const dispatch = useDispatch()

    useEffect(() => {

        let payload = {
            url : `${BACKEND_DOMAIN}/login/authenticate`
        }

        GET(payload).then(res => {

            dispatch(authenticate(res.data.data))
            setUser(res.data.data)

        }).catch(err => {

            dispatch(authenticate({}))
            setUser({})
            return false;
        })

    }, [])


    return user;

}

export default useAuthenticate