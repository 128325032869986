function validateAndSortNumbers(str1, str2, default1 = 0, default2 = 0) {
  // Convert the strings to numbers or use default values if they are not valid numbers
  let num1 = parseFloat(str1);
  let num2 = parseFloat(str2);

  // Check if the conversion resulted in NaN (not a number)
  if (isNaN(num1)) {
    num1 = default1;
  }
  if (isNaN(num2)) {
    num2 = default2;
  }

  // Sort the numbers in ascending order
  let sortedNumbers = [num1, num2].sort((a, b) => a - b);

  return sortedNumbers;
}

const getStudentOrEmpTitle = (organization_id) => {
  const ids_list = [3]
  if (ids_list?.includes(Number(organization_id))) {
    return { s_label: "Student", s_name: "student", p_label: "Students", p_name: "students" }
  } else {
    return { s_label: "Employee", s_name: "employee", p_label: "Employees", p_name: "employees" }
  }
}

module.exports = {
  validateAndSortNumbers,
  getStudentOrEmpTitle
}