import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { CSVLink } from "react-csv";
import {Col, Form, Row,Tab,Nav , Table, Button, Modal } from "react-bootstrap";
import Moment from 'react-moment';
import moment from 'moment-timezone';
import docs_play from '../../../assets/images/icons/docs_play.png';
import grn_excla from '../../../assets/images/icons/grn_excla.png';
import mode_edit from '../../../assets/images/icons/mode_edit.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import eye from '../../../assets/images/icons/eye.svg';
import eye_slash from '../../../assets/images/icons/eye_slash.svg';
import calendar from '../../../assets/images/icons/calendar.png';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';
import PAGINATION from '../../../components/common/pagination';

function SuperAdminUsers(){

    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)
    const [ allCheckedState, setAllCheckedState ] = useState(false)
    const [ checkedState, setCheckedState ] = useState({})
    const [ isDisabled, setIsDisabled ] = useState(true)
    const [ users, setUsers ] = useState({})
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const studentsHeaders = ["Firstname", "Lastname", "Email", "Password", "Division"]
    const generalHeaders = ["Firstname", "Lastname", "Email", "Password"]
    const [ csvData, setCsvData ] = useState([])

    const itemsPerPage = 10
    const [ studentsOffset, setStudentsOffset ] = useState(0);
    const [ adminsOffset, setAdminsOffset ] = useState(0);
    const [ subAdminsOffset, setSubAdminsOffset ] = useState(0);
    const [ coordinatorsOffset, setCoordinatorsOffset ] = useState(0);
    const [ interpretersOffset, setInterpretersOffset ] = useState(0);
    const [ studentsItems, setStudentsItems ] = useState([])
    const [ adminsItems, setAdminsItems ] = useState([])
    const [ subAdminsItems, setSubAdminsItems ] = useState([])
    const [ coordinatorsItems, setCoordinatorsItems ] = useState([])
    const [ deleteUserId, setDeleteUserId ] = useState('')
    const [ deleteUserRole, setDeleteUserRole ] = useState('')
    const [ deleteModalShow, setDeleteModalShow ] = useState(false)
    const handleDeleteModalClose = () => setDeleteModalShow(false)
    const [ interpretersItems, setInterpretersItems ] = useState([])
    const [ studentsPageCount, setStudentsPageCount ] = useState(0);
    const [ adminsPageCount, setAdminsPageCount ] = useState(0);
    const [ subAdminsPageCount, setSubAdminsPageCount ] = useState(0);
    const [ coordinatorsPageCount, setCoordinatorsPageCount ] = useState(0);
    const [ interpretersPageCount, setInterpretersPageCount ] = useState(0);
    const [ activePageNumber, setActivePageNumber ] = useState('');

    var isUsersInitialized = useRef(false)

    /* Maintain tab state code starts here */
    
    const urlParams = new URLSearchParams(window.location.search);
    let defaultTabValue = 'sub_admins'
    let currentPageNumValue = 0
    let initialPageValue = 0

    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')

    if(urlTab == 'interpreters') {
        defaultTabValue = 'interpreters'
    }

    if(urlPage) {
        currentPageNumValue = Number(urlPage) - 1
        initialPageValue = Number(urlPage) - 1
    }

    const [ currentPageNum, setCurrentPageNum ] = useState(currentPageNumValue);
    const initialPageNum = initialPageValue;

    // var initialPageNum = initialPageValueRef.current
    const [ defaultTab, setDefaultTab ] = useState(defaultTabValue);
    const [ currentTabState, setCurrentTabState ] = useState(defaultTabValue);

    let initialCreatePath = 'sub-admin/create'
    if(defaultTabValue == 'sub_admins') {
        initialCreatePath = 'sub-admin/create'
    }else {
        initialCreatePath = 'interpreter/create'
    }

    const [ createPath, setCreatePath ] = useState(initialCreatePath)

    /* Maintain tab state code ends here */

    useEffect(() => {

        if(Object.keys(users).length === 0 && !isUsersInitialized.current) {
            getUsers()
        }

    },[ users ]);

    const getUsers = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/admin/users/list`
        }

        GET(payload).then(res => {

            const { data } = res.data

            console.log(data)

            const subAdminsEndOffset = subAdminsOffset + itemsPerPage;
            const interpretersEndOffset = interpretersOffset + itemsPerPage;
            let interpretersChunk = []
            let subAdminsChunk = []
            let interpretersPageCount = Math.ceil(data.interpreters.length / itemsPerPage);
            let subAdminsPageCount = Math.ceil(data.sub_admins.length / itemsPerPage);

            if(data.interpreters.length > 0) {
                interpretersChunk = data.interpreters.slice(interpretersOffset, interpretersEndOffset)
            }
            if(data.sub_admins.length > 0) {
                subAdminsChunk = data.sub_admins.slice(subAdminsOffset, subAdminsEndOffset)
            }

            setIsNoRecords(false)
            setIsLoading(false)
            
            setInterpretersPageCount(interpretersPageCount)
            setInterpretersItems(interpretersChunk)
            setSubAdminsPageCount(subAdminsPageCount)
            setSubAdminsItems(subAdminsChunk)
            setUsers(data)

            isUsersInitialized.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const deleteUser = (event) => {

        let user_id = deleteUserId
        let role = deleteUserRole

        let data = {
            user_id,
            role
        }

        let payload = {
            url : `${BACKEND_DOMAIN}/admin/users/delete`,
            data
        }

        POST(payload).then(res => {
            setDeleteModalShow(false)
            toast.success(res.data.message)
            getUsers()
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setDeleteModalShow(false)
            toast.error(err.response.data.message)
        })
    }

    const togglePassword = (eLId) => {
        let element = document.getElementById(`password_${eLId}`)
        let eyecon = document.getElementById(`eyecon_${eLId}`)
        if(element.type == 'text') {
            element.setAttribute('type', 'password')
        }else {
            element.setAttribute('type', 'text')
        }

        if(eyecon.getAttribute('src') == eye) {
            eyecon.setAttribute('src', eye_slash) 
        }else {
            eyecon.setAttribute('src', eye)
        }
    }

    const handlePageClick = (event, currentTab) => {

        let items = []

        if(currentTab == 'sub_admins') {
            items = users.sub_admins
        }
       
        if(currentTab == 'interpreters') {
            items = users.interpreters
        }

        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        let checkMap = {}
        for(let x of chunk) {
            checkMap[x.id] = false
        }
        setCheckedState(prevState => {
        return {
            ...checkMap
        }
        })
        setAllCheckedState(false)

        if(currentTab == 'sub_admins') {
            setSubAdminsPageCount(pageCount)
            setSubAdminsItems(chunk)
            setSubAdminsOffset(newOffset);
        }
        if(currentTab == 'interpreters') {
            setInterpretersPageCount(pageCount)
            setInterpretersItems(chunk)
            setInterpretersOffset(newOffset);
        }

        if(chunk.length > 0) {
            initializeCheckBox(chunk)
        }

    };

    const initializeCheckBox = (tempItems) => {

        if(tempItems.length > 0) {
            let checkMap = {}
            for(let x of tempItems) {
                checkMap[x.id] = false
            }
            setCheckedState(prevState => {
                return {
                    ...checkMap
                }
            })
        }
        setIsDisabled(true)
        
    }

    const handleAllCheckBox = (event) => {

        let inverseValue =  !allCheckedState

        let tempCheckstate = {}
        for(let y in checkedState) {
          if(inverseValue == true) {
            tempCheckstate[y] = true
          }else{
            tempCheckstate[y] = false
          }
          
        }

        let checkedData = Object.keys(tempCheckstate).filter(key => tempCheckstate[key] === true)

        if(checkedData.length > 0) {
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }
        
        setAllCheckedState(inverseValue)
        setCheckedState(prevState => {
          return {
            ...prevState,
            ...tempCheckstate
          }
        });
        
    }

    const handleSingleCheckBox = (checkId) => {

        let tempCheckstate = {}
        let tempCheckstateArr = []
        for(let y in checkedState) {
            if(y == checkId) {
                tempCheckstate[y] = !checkedState[y]
            }
        }
        let dummyCheck = {
            ...checkedState,
            ...tempCheckstate
        }

        for(let z in dummyCheck) {
            tempCheckstateArr.push(dummyCheck[z])
        }
        if(tempCheckstateArr.every(element => element === true)) {
            setAllCheckedState(true)
        }else {
            setAllCheckedState(false)
        }

        if(tempCheckstateArr.some(element => element === true)) {
            setIsDisabled(false)
        }else {
            setIsDisabled(true)
        }
        
        setCheckedState(prevState => {
            return {
            ...prevState,
            ...tempCheckstate
            }
        });
    }

    const exportSelected = () => {

        let csvHeaders = []
        let tempUsers = []
        
        csvHeaders = generalHeaders
        
        if(currentTabState == 'interpreters') { 
            tempUsers = interpretersItems
        }
        if(currentTabState == 'sub_admins') { 
            tempUsers = subAdminsItems
        }
        
        let tempCsvData = [[...csvHeaders]]
        for(let x in checkedState) {
            if(checkedState[x] == true) {
                let filterUsers = tempUsers.filter(elem => elem.id == x )
                let filteredUser = []

                if(filterUsers.length > 0) {

                    if(currentTabState == 'students') {
                        filteredUser = [filterUsers[0]['first_name'], filterUsers[0]['last_name'], filterUsers[0]['email'], filterUsers[0]['password'], filterUsers[0]['division_name']]
                    }else {
                        filteredUser = [filterUsers[0]['first_name'], filterUsers[0]['last_name'], filterUsers[0]['email'], filterUsers[0]['password']]
                    }
                    tempCsvData = [...tempCsvData, filteredUser]
                    
                }
            }
        }

        setCsvData(tempCsvData)
            
    }

    const exportAll = () => {

        let csvHeaders = []
        let tempUsers = []
        csvHeaders = generalHeaders
        
        if(currentTabState == 'interpreters') { 
            tempUsers = users.interpreters
        }
        if(currentTabState == 'sub_admins') { 
            tempUsers = users.sub_admins
        }

        let tempCsvData = [[...csvHeaders]]
        for(let x of tempUsers) {
            let filteredUser = []
            
            filteredUser = [x['first_name'], x['last_name'], x['email'], x['password']]
            tempCsvData = [...tempCsvData, filteredUser]
        }

        setCsvData(tempCsvData)

    }

    const changeTab = (tab) => {
        setSearchParams({ tab });
        setCurrentPageNum(0)
        let tempItems = []
        if(tab == 'interpreters') {
            setCreatePath('interpreter/create')
            tempItems = interpretersItems
        }
        if(tab == 'sub_admins') {
            setCreatePath('sub-admin/create')
            tempItems = subAdminsItems
        }
        setCurrentTabState(tab)
        setAllCheckedState(false)
        initializeCheckBox(tempItems)
    }

    const editPage = (elem, type) => {

        let activeItemSelector = ''

        if(currentTabState == 'sub_admins') {
            activeItemSelector = `#fdgdfg-tabpane-sub_admins ul li.page-item.active a`
        }
       
        if(currentTabState == 'interpreters') {
            activeItemSelector = `#fdgdfg-tabpane-interpreters ul li.page-item.active a`
        }

        let activePageNumber = document.querySelector(activeItemSelector).innerText

        setActivePageNumber(activePageNumber)

        if(type == 'sub_admins') {
            navigate(`/staffs/sub-admin/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`)
        }else {

            navigate(`/staffs/interpreter/${elem.id}/edit?tab=${currentTabState}&page=${activePageNumber}`)
        }
    }

    const createPage = () => {
        if(currentTabState == 'sub_admins') {
            if(Object.keys(users).length === 0 || users.roles.length === 0) {
                toast.error('Roles must be created first!', { autoClose: 2000 })
                return
            }
        }

        navigate(`${createPath}?tab=${currentTabState}&page=${activePageNumber}`)
    }

    const handleDeleteModalShow = (usr_id, usr_role) => {

        setDeleteUserId(usr_id)
        setDeleteUserRole(usr_role)
        setDeleteModalShow(true)

    }

    return(
        <>        

            <h2 className="page_hdng">Users</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{(Object.keys(users).length > 0 && users.sub_admins.length > 0) ? users.sub_admins.length : 0}</h4>
                        <p>Sub Admins</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{(Object.keys(users).length > 0 && users.interpreters.length > 0) ? users.interpreters.length : 0}</h4>
                        <p>Interpreters</p>
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <div className="fixed_tab">
                    <Tab.Container id="fdgdfg" defaultActiveKey={defaultTab}>
                        <Row className='justify-content-between'>
                            <Col className='col-auto'>
                                <Nav variant="fixed_pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="sub_admins" onClick={() => changeTab('sub_admins')} >Sub Admins</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="interpreters" onClick={() => changeTab('interpreters')} >Interpreters</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            
                            <Col className='col-auto'>
                                <Row>
                                    <Col className='col-auto'>
                                        <div className='d-flex justify-content-end'>
                                            <CSVLink 
                                                data={csvData} 
                                                filename={`${currentTabState}-${Date.now()}-${Math.round(Math.random() * 1E9)}`}
                                                onClick={() => !isDisabled}
                                            >
                                                <Button size='md' className='px-4 ms-2' disabled={isDisabled} onClick={exportSelected} >Export Selected</Button>
                                            </CSVLink>
                                            <CSVLink 
                                                data={csvData} 
                                                filename={`${currentTabState}-${Date.now()}-${Math.round(Math.random() * 1E9)}`}
                                                onClick={() => (currentTabState == 'interpreters' ? (Object.keys(users).length > 0 && users.interpreters.length > 0) : (currentTabState == 'sub_admins' ? (Object.keys(users).length > 0 && users.sub_admins.length > 0) : false )) }
                                            >
                                                <Button size='md' className='px-4 ms-2' onClick={exportAll} disabled={ (currentTabState == 'interpreters' ? (Object.keys(users).length === 0 || users.interpreters.length === 0) : (currentTabState == 'sub_admins' ? (Object.keys(users).length === 0 || users.sub_admins.length === 0) : false )) } >Export All</Button>
                                            </CSVLink>
                                            { createPath &&
                                                <Button variant='success' size='md' className='px-4 ms-2' onClick={() => createPage()} >{currentTabState == 'sub_admins' ? 'Add Sub Admin' : 'Add Interpreter'}</Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                
                                    
                            </Col>
                        </Row>

                        <Tab.Content>
                            <Tab.Pane eventKey="sub_admins">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th width="50">
                                                {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                </div>
                                                ))}
                                            </th>
                                            <th colSpan="2">Sub Admin Name</th>
                                            <th>Added Since</th>
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { !isLoading && (Object.keys(users).length > 0 && users.sub_admins.length === 0) &&
                                        <tr className="text-center">
                                            <td colSpan="7" className="fs-4">
                                                No Records Found!
                                            </td>
                                        </tr>
                                    }
                                    { !isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.sub_admins.length > 0) &&
                                        subAdminsItems.map((elem, index) => {
                                            return (
                                                <tr key={elem.id}>
                                                    <td width="50">
                                                        {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`}>
                                                            <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                        </div>
                                                        ))}
                                                    </td>
                                                    <td width="50">
                                                        <img className='tbl_usr_img' src={elem.picture_path} />
                                                    </td>
                                                    <td>
                                                        <h5>{elem.full_name}</h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <Moment format="MM/DD/YYYY">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>{elem.email}</h5>
                                                    </td>
                                                    <td className="paddsowd">
                                                        <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                        <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                    </td>
                                                    <td className="text-center">
                                                        {/* <span className="badge badge_md">
                                                            <img src={docs_play} />
                                                        </span>
                                                        <span className="badge badge_md">
                                                            <img src={grn_excla} />
                                                        </span> */}
                                                        
                                                        <span className="badge badge_md" onClick={() => editPage(elem, 'sub_admins')} style={{ cursor: 'pointer' }} >
                                                            <img src={mode_edit} />
                                                        </span>
                                                        
                                                        <NavLink data-role="sub-admin" data-id={elem.id} onClick={event => handleDeleteModalShow(elem.id, elem.role)} >
                                                            <span className="badge badge_md">
                                                                <img src={rd_trash} />
                                                            </span>
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            )}
                                        )
                                    }
                                    </tbody>
                                </Table>
                                { Object.keys(users).length > 0 && users.sub_admins.length > 0 &&
                                    <PAGINATION allItems={users.sub_admins} chunkedItems={subAdminsItems} handlePageClick={event => handlePageClick(event, 'sub_admins')} pageCount={subAdminsPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="interpreters">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th width="50">
                                                {['checkbox'].map((type) => (
                                                    <div key={`inline-${type}`}>
                                                        <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={handleAllCheckBox} checked={allCheckedState} />
                                                    </div>
                                                ))}
                                            </th>
                                            <th colSpan="2">Interpreter Name</th>
                                            <th>Added Since</th>
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { !isLoading && (Object.keys(users).length > 0 && users.interpreters.length === 0) &&
                                        <tr className="text-center">
                                            <td colSpan="7" className="fs-4">
                                                No Records Found!
                                            </td>
                                        </tr>
                                    }
                                    { !isNoRecords && !isLoading && (Object.keys(users).length > 0 && users.interpreters.length > 0) &&
                                        interpretersItems.map((elem, index) => {
                                            return (
                                                <tr key={elem.id}>
                                                    <td width="50">
                                                        {['checkbox'].map((type) => (
                                                        <div key={`inline-${type}`}>
                                                            <Form.Check label="" name="all" type={type} id={`inline-${type}-1`} onChange={() => handleSingleCheckBox(elem.id)} checked={checkedState[elem.id]} />
                                                        </div>
                                                        ))}
                                                    </td>
                                                    <td width="50">
                                                        <img className='tbl_usr_img' src={elem.picture_path} />
                                                    </td>
                                                    <td>
                                                        <h5>{elem.full_name}</h5>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <Moment format="MM/DD/YYYY">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5>{elem.email}</h5>
                                                    </td>
                                                    <td className="paddsowd">
                                                        <input name="pwd" id={`password_${elem.id}`} type={"password"} defaultValue={elem.password} readOnly />
                                                        <img width={20} id={`eyecon_${elem.id}`} src={eye_slash} onClick={() => togglePassword(elem.id)} />
                                                    </td>
                                                    <td className="text-center">
                                                        {/* <span className="badge badge_md">
                                                            <img src={docs_play} />
                                                        </span>
                                                        <span className="badge badge_md">
                                                            <img src={grn_excla} />
                                                        </span> */}
                                                       
                                                        <span className="badge badge_md" onClick={() => editPage(elem, 'interpreters')} style={{ cursor: 'pointer' }} >
                                                            <img src={mode_edit} />
                                                        </span>
                                                        <a href="javascript:void(0)" data-role="interpreter" data-id={elem.id} onClick={event => handleDeleteModalShow(elem.id, elem.role)} >
                                                            <span className="badge badge_md">
                                                                <img src={rd_trash} />
                                                            </span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )}
                                        )
                                    }
                                    </tbody>
                                </Table>
                                { Object.keys(users).length > 0 && users.interpreters.length > 0 &&
                                    <PAGINATION allItems={users.interpreters} chunkedItems={interpretersItems} handlePageClick={event => handlePageClick(event, 'interpreters')} pageCount={interpretersPageCount} currentPageNumber={currentPageNum} initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </div>
                </Col>
            </Row>

            <Modal className="approve-modal" show={deleteModalShow} onHide={handleDeleteModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Are you sure ? You want to delete.</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleDeleteModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => deleteUser()}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SuperAdminUsers;