import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {Col, Form, InputGroup, Row } from "react-bootstrap";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../../config/variables';
import { GET, PATCH } from '../../../../utils/axios.util';

import eye from '../../../../assets/images/icons/eye.svg';
import eye_slash from '../../../../assets/images/icons/eye_slash.svg';

function SuperAdminSubAdminEdit(){
    
    const { register, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const params = useParams();
    const location = useLocation();

    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [ subAdmin, setSubAdmin ] = useState({})
    const [ institutes, setInstitutes ] = useState([])
    const [ roles, setRoles ] = useState([])
    const [ instituteOptions, setInstituteOptions ] = useState(null)
    const [ roleOptions, setRoleOptions ] = useState(null)
    const [ slug, setSlug ] = useState('')
    const [ phone, setPhone ] = useState('');
    const [ contactPhone, setContactPhone ] = useState('');
    const orgnz_id = params.org_id
    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')

    useEffect(() => {

        let slugParam = ''
        let saffPattern = /staffs/i

        if(saffPattern.test(location.pathname)) {
            slugParam = 'staff'
            setSlug('staff')
        }else {
            slugParam = 'org'
            setSlug('org')
        }

        if(roles.length === 0){
            getRoles()
        }

        if(Object.keys(subAdmin).length === 0) {
            getSubAdmin(slugParam)
        }

        /* if(institutes.length === 0){
            getInstitutes()
        }

        if(institutes.length > 0 && !instituteOptions) {
            let instituteValues =  institutes.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 
            setInstituteOptions(instituteValues)
        } */

    },[ subAdmin ]);

    const getRoles = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/admin/roles`
        }

        GET(payload).then(res => {
            const { data } = res.data

            let roleOptionValues =  data.map((e)=>{
                return (
                    <option key={e.id} value={e.id}>{e.name}</option>
                )
            }) 

            setRoleOptions(roleOptionValues)
            setRoles(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const getSubAdmin = (slugParam = '') => {

        let URL = `${BACKEND_DOMAIN}/admin/sub-admin/${params.id}`

        if(slugParam == 'org') {
            URL = `${BACKEND_DOMAIN}/admin/sub-admin/organization/${orgnz_id}/${params.id}`
        }

        let payload = {
            url : URL
        }

        GET(payload).then(res => {
            const { data } = res.data

            console.log(data)
            setPhone(data.phone)
            setSubAdmin(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 404) {
                if(slugParam == 'org') {
                    navigate(`/organizations/${orgnz_id}/users`)
                }else {
                    navigate('/staffs')
                }
            }
        })

    }

   /*  const getInstitutes = () => {

        let payload = {
            url : `${BACKEND_DOMAIN}/admin/institutes`
        }

        GET(payload).then(res => {
            const { data } = res.data
            setInstitutes(data)
        }).catch(err => {

            console.log(err)
        })

    } */
    
    const previousPage = () => {

        let navigateUrl = ''

        if(slug == 'staff') {

            navigateUrl = '/staffs'

            if(urlTab) {
                navigateUrl = `/staffs?tab=${urlTab}`
            }

            if(urlTab && urlPage) {
                navigateUrl = `/staffs?tab=${urlTab}&page=${urlPage}`
            }

        }else {

            navigateUrl = `/organizations/${orgnz_id}/users`

            if(urlTab) {
                navigateUrl = `/organizations/${orgnz_id}/users?tab=${urlTab}`
            }

            if(urlTab && urlPage) {
                navigateUrl = `/organizations/${orgnz_id}/users?tab=${urlTab}&page=${urlPage}`
            }

        }

        navigate(navigateUrl)
    }

    const onSubmit = (data) => {

        clearErrors('phone')
        clearErrors('contact_phone')

        data['phone'] = phone
        data['contact_phone'] = contactPhone

        if (!data.phone) {
            setError("phone", {
              type: "manual",
              message: "Phone number is required"
            });
            return;
        }

        let URL = `${BACKEND_DOMAIN}/admin/sub-admin/${params.id}/edit`

        if(slug == 'org') {
            URL = `${BACKEND_DOMAIN}/admin/sub-admin/organization/${orgnz_id}/${params.id}/edit`
        }

        let payload = {
            url: URL,
            data
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)

            let navigateUrl = ''

            if(slug == 'org') {

                navigateUrl = `/organizations/${orgnz_id}/users`

                if(urlTab) {
                    navigateUrl = `/organizations/${orgnz_id}/users?tab=${urlTab}`
                }

                if(urlTab && urlPage) {
                    navigateUrl = `/organizations/${orgnz_id}/users?tab=${urlTab}&page=${urlPage}`
                }

            }else {

                navigateUrl = '/staffs'

                if(urlTab) {
                    navigateUrl = `/staffs?tab=${urlTab}`
                }

                if(urlTab && urlPage) {
                    navigateUrl = `/staffs?tab=${urlTab}&page=${urlPage}`
                }

            }

            navigate(navigateUrl)

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const allowOnlyNumber=(e, type)=>{

        if(type == 'sub_admin') {
            setPhone(e.target.value)
        }else {
            setContactPhone(e.target.value)
        }

        let maxLength = 9;
        // let valLength = e.target.value.length

        let pattern = /^\d$/
        let count = 0
        for (let i = 0; i < e.target.value.length; i++) {
           
            if(pattern.test(e.target.value.charAt(i))) {
                count++
            }
        }

        let valLength = count

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if(valLength > maxLength){
            
            if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46) ) {
                return true;
            }else {

                e.preventDefault();
            }
            // e.target.value = e.target.value.substr(0, e.target.value.length-1);
        };

        if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {
            return true;
        }else {
            e.preventDefault();
        }
        /* if((isNaN(charValue)) && (e.which != 8 )){ // BSP KB code is 8
            e.preventDefault();
        } 
        return true; */
        
    }

    const formatUSNumber = (e, type) => {

        if(type == 'sub_admin') {
            clearErrors('phone')
        }else {
            clearErrors('contact_phone')
        }

        let entry = e.target.value

        if(entry != '') {

            let count = 0
            for (let i = 0; i < entry.length; i++) {

                let pattern = /^\d$/
                if(pattern.test(entry.charAt(i))) {
                    count++
                }
            }

            let fieldName = ''
            if(type == 'sub_admin') {
                fieldName = 'phone'
            }else {
                fieldName = 'contact_phone'
            }

            if(count != 10) {
                setError(fieldName, {
                    type: "manual",
                    message: "Number must be ten digits"
                  });
                  return;
            }

            const match = entry
            .replace(/\D+/g, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
            const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
            const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
            const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''  
            e.target.value = `${part1}${part2}${part3}`

            if(type == 'sub_admin') {
                setPhone(`${part1}${part2}${part3}`)
            }else {
                setContactPhone(`${part1}${part2}${part3}`)
            }

            return `${part1}${part2}${part3}`
        }
        
    }

    return(
        <>
            { Object.keys(subAdmin).length > 0 && roleOptions &&
            <>
                <h2 className="page_hdng">Users </h2>
                <Row className="mt-5 justify-content-center">
                    <Col sm="5">
                        <div>
                            <h4 className='text-center'>Edit Sub Admin</h4>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <p className='dfn_label'>Basic Info</p>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control type="text" name="first_name" defaultValue={subAdmin.first_name} onChange={(event) => setValue('first_name', event.target.value)} {...register('first_name', { required: "First name is required" })} autoComplete="off"/>

                                            {errors.first_name ?.message && <span className="error">{errors.first_name ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control type="text" name="last_name" defaultValue={subAdmin.last_name} onChange={(event) => setValue('last_name', event.target.value)} {...register('last_name')} autoComplete="off" />
                                        </Form.Group>
                                    </Col>

                                    { slug == 'staff' &&
                                        <>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control name="address" type="text" defaultValue={subAdmin.address} {...register('address', { required: "Address is required" })} autoComplete="off" />

                                                    {errors.address ?.message && <span className="error">{errors.address ?.message}</span> }
                                                </Form.Group>
                                            </Col> 
                                        </>
                                    }

                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control name="phone" type="text" defaultValue={phone} onKeyDown={(event) => allowOnlyNumber(event)} onBlur={formatUSNumber} autoComplete="off" />

                                            {errors.phone ?.message && <span className="error">{errors.phone ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                    <Col sm="6">
                                        <Form.Group className="mb-3" controlId="role">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Select className="org-dropdown" name="role" defaultValue={subAdmin.role_id} {...register("role", { required: "Role is required" })} >
                                                <option value="" >-- Role --</option>
                                                {roleOptions}
                                            </Form.Select>

                                            {errors.role ?.message && <span className="error">{errors.role ?.message}</span> }

                                        </Form.Group>
                                    </Col>
                                    
                                </Row>

                                { slug == 'staff' &&

                                    <>
                                        <p className='dfn_label'>Emergency Contact</p>
                                        <Row className="mb-3">
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" name="contact_name" defaultValue={subAdmin.contact_name} onChange={(event) => setValue('contact_name', event.target.value)} {...register('contact_name')} autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Relationship</Form.Label>
                                                    <Form.Control type="text" name="contact_relationship" defaultValue={subAdmin.contact_relationship} onChange={(event) => setValue('contact_relationship', event.target.value)} {...register('contact_relationship')} autoComplete="off" />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" name="contact_email" defaultValue={subAdmin.contact_email} onChange={(event) => setValue('contact_email', event.target.value)} {...register('contact_email')} autoComplete="off" />

                                                    {errors.contact_email && <span className="error">{errors.contact_email.message}</span> }

                                                </Form.Group>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control name="contact_phone" type="text" defaultValue={subAdmin.contact_phone} onKeyDown={(event) => allowOnlyNumber(event, 'contact_phone')} onBlur={(event) => formatUSNumber(event, 'contact_phone')} autoComplete="off" />

                                                    {errors.contact_phone && <span className="error">{errors.contact_phone.message}</span> }

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                }

                                <p className='dfn_label'>Credentials</p>
                                <Row className="mb-3">
                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>User Name (Email)</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" name="email" defaultValue={subAdmin.email} onChange={(event) => setValue('email', event.target.value)} {...register('email', { 
                                                required: "User Name is required",
                                                pattern: { 
                                                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: "Invalid email address"
                                                } 
                                            })} autoComplete="off" />

                                            {errors.email ?.message && <span className="error">{errors.email ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                    <Col sm="6">
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Password</Form.Label>
                                                <InputGroup className="password_frm_grp">
                                                    <Form.Control name="password" placeholder="Enter Password" type={isRevealPwd ? "text" : "password"} defaultValue={subAdmin.password} onChange={e => { setPwd(e.target.value); setValue('password', e.target.value) }} {...register("password", { required: "Password is required" } )} autoComplete="new-password"/>
                                                    <InputGroup.Text id="basic-addon2">
                                                        <img width={20} title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? eye : eye_slash} onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                {errors.password ?.message && <span className="error">{errors.password ?.message}</span> }
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3 justify-content-center">
                                    <Col className='col-auto'>
                                        <button className='cancel_btn' onClick={() => previousPage()} >Cancel</button>
                                    </Col>
                                    <Col className='col-auto'>
                                        <button className='save_btn' type="submit" >Save</button>
                                    </Col>
                                </Row>
                            </Form>

                        </div>
                    </Col>
                </Row>
            </>
            }
        </>
    )
}

export default SuperAdminSubAdminEdit;