import React, { useEffect, useRef } from "react";

import moment from 'moment-timezone';
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";

import useAuthenticate from '../../hooks/useAuthenticate';

import { ref, update, onChildAdded, onChildChanged } from "firebase/database";
import firebaseDb from '../../utils/firebase.util';
import { TIME_ZONE } from '../../config/variables';

import { chat } from "../../redux/actions";

function SuperAdminInterfaceCtrl(props){

    const user = useAuthenticate()
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const chatInitializeRef = useRef(false)
    var chatInitialize = chatInitializeRef.current
    var usersList = {}
    var keysArr = []

    useEffect(() => {

        if(!chatInitialize) {
            // initiateChats()
        }

    }, [ user ])

    // const childAddedPromise = async (keySelector) => {

    //     return new Promise(async function (resolve, reject) {

    //         console.log(keySelector)

    //         onChildAdded(keySelector, (data) => {

    //             var otherUser = null

    //             let value = data.val()

    //             console.log('value', value)

    //             for(let itemKey in value) {

    //                 let usrItem = value[itemKey]
                    
    //                 if(usrItem.role != 'super_admin' && usrItem.role != 'sub_super_admin') {
                        
    //                     otherUser = usrItem
    //                     let keyName = `${usrItem.user_id}_${usrItem.role}`

    //                     if(!usersList.hasOwnProperty(keyName)) {
    //                         usersList[keyName] = []
    //                     }

    //                     if(!keysArr.includes(itemKey)) {
    //                         keysArr.push(itemKey)
    //                         usersList[keyName].push(usrItem)
    //                     }
    //                 }

    //             }

    //             /* Update status to Online, Offline */

    //             for(let itmKey in value) {

    //                 let usrItm = value[itmKey]

    //                 if(otherUser) {
    //                     if(usrItm.role == user.role && usrItm.user_id == user.id) {
    //                         const updates = {};
    //                         updates[`support/user_${otherUser.user_id}/${itmKey}`] = {
    //                             ...value,
    //                             status: 'online',
    //                             timestamp: moment().tz(TIME_ZONE).format('XX'),
    //                             timestampFormatted: moment().tz(TIME_ZONE).format('YYYY-MM-DD HH:mm:ss')
    //                         };

    //                         update(ref(firebaseDb), updates);
    //                     }
    //                 }

    //             }

    //             dispatch(chat(usersList, user))

    //         });

    //         console.log(usersList)

    //         return resolve(usersList)
    //     })

    // }

    // const childChangedPromise = async (keySelector) => {

    //     return new Promise(function (resolve, reject) {

    //         onChildChanged(keySelector, (data) => {

    //             // if(Object.keys(usersList).length > 0) {
    
    //                 let value = data.val()
    //                 // let chatList = []
    
    //                 console.log('*******onChildChanged changed*******')
    //                 console.log(value)
                    
    //                 for(let key in value) {
    
    //                     let usrItem = value[key]
    
    //                     if(usrItem.role != 'super_admin' && usrItem.role != 'sub_super_admin') {
    
    //                         let keyName = `${usrItem.user_id}_${usrItem.role}`
    //                         if(!usersList.hasOwnProperty(keyName)) {
    //                             usersList[keyName] = []
    //                         }
    
    //                         if(!keysArr.includes(key)) {
    //                             keysArr.push(key)
    //                             usersList[keyName].push(usrItem)
    //                         }
    //                     }
    //                 }
    
    //                 /* for(let chatKey in value) {
    //                     chatList.push(value[chatKey])
    //                 }
    
    //                 for(let x of userChats) {
    //                     if(x['key'] == data.key) {
    
    //                         for(let newVal in data.val()) {
    //                             x[newVal] = data.val().newVal
    //                         }
                            
    //                     }
    //                 } */
    //             // }
    
    //         });

    //         console.log(usersList)

    //         return resolve(usersList)
    //     })

    // }

    // const initiateChats = async () => {

    //     if(Object.keys(user).length > 0) {

    //         const chatListRef = ref(firebaseDb, `support`);

    //         chatInitialize = true

    //         await childAddedPromise(chatListRef)
    //         await childChangedPromise(chatListRef)

            

    //         // onChildAdded(chatListRef, (data) => {

    //         //     var otherUser = null

    //         //     let value = data.val()

    //         //     for(let itemKey in value) {

    //         //         let usrItem = value[itemKey]
                    
    //         //         if(usrItem.role != 'super_admin' && usrItem.role != 'sub_super_admin') {
                        
    //         //             otherUser = usrItem
    //         //             let keyName = `${usrItem.user_id}_${usrItem.role}`

    //         //             if(!usersList.hasOwnProperty(keyName)) {
    //         //                 usersList[keyName] = []
    //         //             }

    //         //             if(!keysArr.includes(itemKey)) {
    //         //                 keysArr.push(itemKey)
    //         //                 usersList[keyName].push(usrItem)
    //         //             }
    //         //         }

    //         //     }

    //         //     /* Update status to Online, Offline */

    //         //     for(let itmKey in value) {

    //         //         let usrItm = value[itmKey]

    //         //         if(otherUser) {
    //         //             if(usrItm.role == user.role && usrItm.user_id == user.id) {
    //         //                 const updates = {};
    //         //                 updates[`support/user_${otherUser.user_id}/${itmKey}`] = {
    //         //                     ...value,
    //         //                     status: 'online',
    //         //                     timestamp: moment().tz(TIME_ZONE).format('XX'),
    //         //                     timestampFormatted: moment().tz(TIME_ZONE).format('YYYY-MM-DD HH:mm:ss')
    //         //                 };
        
    //         //                 update(ref(firebaseDb), updates);
    //         //             }
    //         //         }
    
    //         //     }

    //         //     dispatch(chat(usersList, user))

    //         // });

    //         // onChildChanged(chatListRef, (data) => {


    //         //     // if(Object.keys(usersList).length > 0) {

    //         //         let value = data.val()
    //         //         // let chatList = []

    //         //         console.log('*******onChildChanged changed*******')
    //         //         console.log(value)
                    
    //         //         for(let key in value) {

    //         //             let usrItem = value[key]

    //         //             if(usrItem.role != 'super_admin' && usrItem.role != 'sub_super_admin') {

    //         //                 let keyName = `${usrItem.user_id}_${usrItem.role}`
    //         //                 if(!usersList.hasOwnProperty(keyName)) {
    //         //                     usersList[keyName] = []
    //         //                 }

    //         //                 if(!keysArr.includes(key)) {
    //         //                     keysArr.push(key)
    //         //                     usersList[keyName].push(usrItem)
    //         //                 }
    //         //             }
    //         //         }

    //         //         /* for(let chatKey in value) {
    //         //             chatList.push(value[chatKey])
    //         //         }

    //         //         for(let x of userChats) {
    //         //             if(x['key'] == data.key) {

    //         //                 for(let newVal in data.val()) {
    //         //                     x[newVal] = data.val().newVal
    //         //                 }
                            
    //         //             }
    //         //         } */
    //         //     // }

    //         //     console.log('***********before dispatch**************')
    //         //     console.log(usersList)

    //         //     dispatch(chat(usersList, user))

                
    //         // });

            

    //     }

    // }

    if(Object.keys(user).length > 0 && user.role === 'super_admin') {

        return(
            <>
                <section className="School_interface">
                    <Outlet/>
                </section>
            </>
        )
    }else {
       
        return navigate('/login')
    }

}

export default SuperAdminInterfaceCtrl;