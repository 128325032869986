const initialState = {
    unread: 0,
    users: {}
}

const chatReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'UPDATE':

            var users = action.payload.users
            var count = 0

            if(Object.keys(users).length > 0) {

                for(let key in users) {

                    for(let x of users[key]) {

                        if( ( x['role'] != 'super_admin' && x['role'] != 'sub_super_admin' ) && (x['is_seen'] === 0) ) {
                            
                            console.log(`unseen`)

                            count++

                        }

                    }
                    
                }
            }

            console.log('count', count)

            return {
                ...state,
                unread: count,
                users: action.payload.users
            }
        default: return state
    }
}

export default chatReducer