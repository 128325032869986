import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { POST } from '../../../utils/axios.util';

function PaymentCard(props) {

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [displayDate, setDisplayDate] = useState('')
    const [date, setDate] = useState(new Date(moment().tz(TIME_ZONE)))

    useEffect(() => {

        if (Object.keys(props.cardDetails).length > 0) {
            setDate(new Date(moment(props.cardDetails.expiry_date)))
            setDisplayDate(moment(props.cardDetails.expiry_date).format('MM/YYYY'))
            setValue('expiry_date', moment(props.cardDetails.expiry_date).format('YYYY-MM-DD'))
            setValue('name', props.cardDetails.name)
            setValue('number', props.cardDetails.number)
            setValue('cvv', props.cardDetails.cvv)
            setValue('zip_code', props.cardDetails.zip_code)
            setValue('address', props.cardDetails.address)
            setValue('city', props.cardDetails.city)
            setValue('state', props.cardDetails.state)

        } else {
            setDate(new Date(moment().tz(TIME_ZONE)))
            setDisplayDate('')
            setValue('expiry_date', '')
            reset()
        }

    }, [props.cardDetails])

    const onSubmit = (data) => {

        let payload = {
            url: `${BACKEND_DOMAIN}/card/save`,
            data
        }

        POST(payload).then(res => {

            toast.success(res.data.message)
            setDisplayDate('')
            reset()
            props.getTransactions()

        }).catch(err => {

            // Session Logout Code
            if (err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const DATE = forwardRef(({ value, onClick }, ref) => (
        <Form.Control type="text" name="expiry_date" placeholder="12/2025" {...register("expiry_date", { required: "Exp Date is required" })} defaultValue={displayDate} onClick={onClick} ref={ref} onChange={(e) => e.target.value = displayDate} autoComplete="off" />
    ));

    const handleNumber = (event) => {
        if (event.target.value.length > 16) {
            event.target.value = event.target.value.slice(0, 16)
        }
        setValue('number', event.target.value)
    }

    const handleCVV = (event) => {
        if (event.target.value.length > 3) {
            event.target.value = event.target.value.slice(0, 3)
        }
        setValue('cvv', event.target.value)
    }

    return (
        <Modal backdrop="static" className="" {...props} aria-labelledby="" centered>
            <Modal.Header closeButton>
                <Modal.Title id="">Add New Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="card-form" onSubmit={handleSubmit(onSubmit)} >

                    <Form.Group className="form-group mb-3">
                        <Form.Label>Card Holder's Name</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Noman Manzoor"
                            {...register("name", {
                                required: "Name is required",
                            })} autoComplete="off" />

                        {errors.name?.message && <span className="error">{errors.name?.message}</span>}

                    </Form.Group>

                    <Form.Group className="form-group mb-3">
                        <Form.Label>Card Number</Form.Label>
                        <Form.Control type="number" name="number" placeholder="9865  1451  2654  2156" onInput={handleNumber} {...register("number", {
                            required: "Card Number is required",
                            minLength: {
                                value: 16,
                                message: "Number must be 16 digits"
                            }
                        })}autoComplete="off" />

                        {errors.number?.message && <span className="error">{errors.number?.message}</span>}

                    </Form.Group>

                    <Row className="justify-content-between">
                        <Col>
                            <Form.Group className="form-group mb-3">
                                <Form.Label>Exp Date</Form.Label>

                                <DatePicker
                                    showMonthYearPicker
                                    dateFormat="MM/yyyy"
                                    selected={date}
                                    minDate={new Date(moment().tz(TIME_ZONE))}
                                    onChange={(date) => { setDate(date); setDisplayDate(moment(date).format('MM/YYYY')); setValue('expiry_date', moment(date).format('YYYY-MM-DD')) }}
                                    customInput={<DATE />}
                                />

                                {errors.expiry_date?.message && <span className="error">{errors.expiry_date?.message}</span>}

                            </Form.Group>
                        </Col>
                        <Col className="col-auto">
                            <Form.Group className="form-group mb-3">
                                <Form.Label>CVV</Form.Label>
                                <Form.Control type="number" name="cvv" placeholder="958" onInput={handleCVV}
                                    {...register("cvv", {
                                        required: "CVV is required",
                                        minLength: {
                                            value: 3,
                                            message: "CVV must be 3 digits"
                                        }
                                    })} autoComplete="off" />

                                {errors.cvv?.message && <span className="error">{errors.cvv?.message}</span>}

                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="justify-content-between">
                        <Col>
                            <Form.Group className="form-group mb-3">
                                <Form.Label>Billing Zip Code</Form.Label>
                                <Form.Control type="text" name="zip_code"
                                    {...register("zip_code", {
                                        required: "Billing Zip Code is required",
                                    })} autoComplete="off"/>

                                {errors.zip_code?.message && <span className="error">{errors.zip_code?.message}</span>}

                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="justify-content-between">
                            <Form.Group className='mb-3'>
                                <Form.Label>Billing Address</Form.Label>
                                <Form.Control name="address" placeholder="Street address " type="text" {...register('address', { required: "Address is required" })} autoComplete="off" />

                                {errors.address?.message && <span className="error">{errors.address?.message}</span>}
                            </Form.Group>
                        <Col className='d-flex justify-content-between ' >
                            <Form.Group className='mb-3'>
                                <Form.Label>City</Form.Label>
                                <Form.Control name="city" type="text" {...register('city', { required: "City is required" })} autoComplete="off"/>
                                {errors.city?.message && <span className="error">{errors.city?.message}</span>}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>State</Form.Label>
                                <Form.Control name="state" type="text" {...register('state', { required: "State is required" })}  autoComplete="off" />
                                {errors.state?.message && <span className="error">{errors.state?.message}</span>}
                            </Form.Group>
                        </Col>

                    </Row>

                    <Row className="justify-content-center my-3">
                        <Col className="col-7">
                            <Form.Group className="form-group d-grid">
                                <div className='d-grid'>
                                    <Button variant='success' size='lg' className='mt-3' type="submit" >Submit</Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
}


export default PaymentCard;