import React, { useState, useEffect, useRef } from 'react';
import {Modal, Breadcrumb, Button,  Col,Form,FormControl,InputGroup,Nav, Row,Tab, Table} from "react-bootstrap";
import Moment from 'react-moment';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mode_edit from '../../../assets/images/icons/mode_edit.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import search from '../../../assets/images/icons/search.png';
import member5 from '../../../assets/images/member5.png';
import eye from '../../../assets/images/icons/eye.svg';
import eye_slash from '../../../assets/images/icons/eye_slash.svg';
import check from '../../../assets/images/icons/check_lg.png';

import { GET, POST, PATCH } from '../../../utils/axios.util';
import { BACKEND_DOMAIN } from '../../../config/variables';
import PAGINATION from '../../../components/common/pagination';

function Packages(){

    const { register, handleSubmit, setValue, setError, clearErrors, reset, formState: { errors } } = useForm();

    const urlParams = new URLSearchParams(window.location.search);
    const [ keyword, setKeyword ] = useState('')
    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false)

    const itemsPerPage = 10
    const [ itemOffset, setItemOffset ] = useState(0);
    const [ currentItems, setCurrentItems ] = useState([])
    const [ pageCount, setPageCount ] = useState(0);

    var isPackagesInitialized = useRef(false)

    const [ packages, setPackages ] = useState([]);
    const [ deleteModalShow, setDeleteModalShow ] = useState(false);
    const [ deletePackageId, setDeletePackageId ] = useState('');
    const handleDeleteModalClose = () => setDeleteModalShow(false);

    let currentPageNumValue = 0
    let initialPageValue = 0

    var urlPage = urlParams.get('page')

    if(urlPage) {
        currentPageNumValue = Number(urlPage) - 1
        initialPageValue = Number(urlPage) - 1
    }

    const [ currentPageNum, setCurrentPageNum ] = useState(currentPageNumValue);
    const initialPageNum = initialPageValue;

    useEffect(() => {

        if(packages.length === 0 && !isPackagesInitialized.current) {
            getPackages(keyword)
        }

    },[ packages, currentItems, itemOffset, itemsPerPage ]);

    const getPackages = (keyword) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/admin/packages?search=${keyword}`
        }

        GET(payload).then(res => {

            const { data } = res.data

            const endOffset = itemOffset + itemsPerPage;
            let pageCount = Math.ceil(data.length / itemsPerPage);
            let chunk = data.slice(itemOffset, endOffset)

            isPackagesInitialized.current = true
            setIsNoRecords(false)
            if(data.length === 0) {
                setIsNoRecords(true)
            }
            setIsLoading(false)
            setPackages(data)
            setPageCount(pageCount)
            setCurrentItems(chunk)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            if(err.response.status === 404) {
                setIsNoRecords(true)
            }
        })

    }

    const deletePackage = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/admin/packages/delete`,
            data: { 
                package_id: deletePackageId
            }
        }

        POST(payload).then(res => {

            setIsLoading(false)
            setDeleteModalShow(false)
            toast.success(res.data.message)
            getPackages()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            setDeleteModalShow(false)
            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const handlePageClick = (event) => {

        let items = packages

        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        setPageCount(pageCount)
        setCurrentItems(chunk)
        setItemOffset(newOffset);
            
    };

    const handleDeleteModalShow = (elem) => {
        setDeletePackageId(elem.id)
        setDeleteModalShow(true)
    }

    const editPage = (elem, type) => {

        let activeItemSelector = `#package-list ul li.page-item.active a`

        let activePageNumber = document.querySelector(activeItemSelector).innerText

        if(type == 'edit') {

            navigate(`/packages/${elem.id}/edit?page=${activePageNumber}`)
        }else {

            navigate(`/packages/${elem.id}/edit?page=${activePageNumber}`, {state: { isView: true }})
        }


    }

    return(
        <>        

            <h2 className="page_hdng">Packages</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="3">
                    <div className="assignments_card">
                        <h4>{packages.length}</h4>
                        <p>Packages</p>
                    </div>
                </Col>
            </Row>
            
                        
            <Row className="">
                <Col xl="12" className="mt-3"> 
                    <Row className=" align-items-center">
                        <Col>
                            <Breadcrumb className='mb-0'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        
                        <Col className="col-auto">
                            <div className='d-flex align-items-center'>
                                <InputGroup className="">
                                    <FormControl size='md' placeholder="search" onChange={(event) => getPackages(event.target.value)}/>
                                        <Button variant='secondary' size='md'>
                                            <img width={20} src={search} />
                                        </Button>
                                </InputGroup>
                                
                                <NavLink to='/packages/create' className="btn btn-success btn-md px-4 ms-2" >Add Package</NavLink>
                                
                            </div>
                        </Col>
                    </Row>

                    <div id="package-list" className="my_card fixed_tab mt-3">
                        <Table className="">
                            <thead>
                                <tr>
                                    <th className='text-start'>Name</th>
                                    <th className='text-start'>Date & Time</th>
                                    <th className='text-start'>Tier</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { isLoading && 
                                    <div className='text-center subscriptions_packages_name no_records_loading'>
                                        <h5>Loading...</h5>
                                    </div>
                                }
                                { !isLoading && isNoRecords &&
                                    <tr className="text-center">
                                        <td colSpan="6" className="fs-4">
                                            No Records Found!
                                        </td>
                                    </tr>
                                }
                                { !isNoRecords && !isLoading && packages.length > 0 &&
                                    currentItems.map((elem, index) => {
                                        return (
                                            <tr key={elem.id}>

                                                <td className="fs-14">
                                                    <p>{elem.name}</p>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <Moment format="MM/DD/YY">
                                                            {elem.created_at}
                                                        </Moment>
                                                    </h5>
                                                    <span className='text_brand_color3'>
                                                        <Moment format="hh:mm A">
                                                            {elem.created_at}
                                                        </Moment>
                                                    </span>
                                                </td>
                                                <td className="fs-14">
                                                    <p>{elem.level}</p>
                                                </td>
                                                <td className='fs-14 text-center'>
                                                    <Button variant='dark'  size='sm' onClick={() => editPage(elem, 'view')} >View</Button>&nbsp;|&nbsp;
                                                    <span className="badge badge_md" onClick={() => editPage(elem, 'edit')} style={{ cursor: 'pointer' }} >
                                                        <img src={mode_edit} />
                                                    </span>
                                                    <span className="badge badge_md" onClick={() => handleDeleteModalShow(elem)} style={{cursor: 'pointer'}}>
                                                        <img src={rd_trash} />
                                                    </span>
                                                </td>
                                            </tr>
                                            )
                                        })
                                    }
                            </tbody>
                        </Table>
                        { packages.length > 0 &&
                            <PAGINATION allItems={packages} chunkedItems={currentItems} handlePageClick={event => handlePageClick(event)} pageCount={pageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                        }
                    </div>                   
                </Col>

            </Row>

            <Modal className="approve-modal" show={deleteModalShow} onHide={handleDeleteModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Are you sure you want to delete ?</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleDeleteModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => deletePackage()}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Packages;