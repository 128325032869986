import { configureStore } from '@reduxjs/toolkit'
import authenticateReducer from './reducers/authenticate.js'
import audioReducer from './reducers/audio.js'
import chatReducer from './reducers/chat.js'

const store = configureStore({
    reducer: {
        authenticate: authenticateReducer,
        audio: audioReducer,
        chat: chatReducer
    }
})

export default store