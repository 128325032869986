import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import SuperAdminInterface from '../Interfaces/SuperAdmin';
import SuperAdminProfile from '../Interfaces/SuperAdmin/Profile';
import SuperAdminDashboard from '../Interfaces/SuperAdmin/Dashboard';
import SuperAdminUsers from '../Interfaces/SuperAdmin/Users';
import SuperAdminCoordinatorCreate from '../Interfaces/SuperAdmin/Users/Coordinator/create';
import SuperAdminSubAdminCreate from '../Interfaces/SuperAdmin/Users/SubAdmin/create';
import SuperAdminStudentCreate from '../Interfaces/SuperAdmin/Users/Student/create';
import SuperAdminInterpreterCreate from '../Interfaces/SuperAdmin/Users/Interpreter/create';
import SuperAdminCoordinatorEdit from '../Interfaces/SuperAdmin/Users/Coordinator/edit';
import SuperAdminSubAdminEdit from '../Interfaces/SuperAdmin/Users/SubAdmin/edit';
import SuperAdminStudentEdit from '../Interfaces/SuperAdmin/Users/Student/edit';
import SuperAdminInterpreterEdit from '../Interfaces/SuperAdmin/Users/Interpreter/edit';
import SuperAdminInterpretation from '../Interfaces/SuperAdmin/Interpretation';
import SuperAdminProfileEdit from '../Interfaces/SuperAdmin/Profile/edit';
import Organizations from '../Interfaces/SuperAdmin/Organizations';
import Packages from '../Interfaces/SuperAdmin/Packages';
import PackageCreate from '../Interfaces/SuperAdmin/Packages/create';
import PackageEdit from '../Interfaces/SuperAdmin/Packages/edit';
import OrganizationUsers from '../Interfaces/SuperAdmin/Organizations/Users';
import SuperAdminChat from '../Interfaces/SuperAdmin/Chat';
import ChatHOC from '../HOC/chatHOC';
import Layout from '../Interfaces/SuperAdmin/Common';
import Tickets from '../Interfaces/SuperAdmin/Support';
import Role from '../Interfaces/SuperAdmin/Role';
import RoleCreate from '../Interfaces/SuperAdmin/Role/create';
import RoleEdit from '../Interfaces/SuperAdmin/Role/edit';

import Login from '../components/common/login';
import Logout from '../components/common/logout';
import ForgotPassword from '../components/common/forgotPassword';
import ViewAssignment from '../components/common/viewAssignment';
import SupportForm from '../components/common/support';
import Payments from '../components/common/payments';
import AssignInterpreter from '../components/common/assignInterpreter';
import Notification from '../components/common/notification';

import SchoolType from '../Interfaces/SuperAdmin/SchoolType';
import SchoolCreate from '../Interfaces/SuperAdmin/SchoolType/create';
import SchoolEdit from '../Interfaces/SuperAdmin/SchoolType/edit';
const Root = () => {

    return (
        <>
            <Routes>

                <Route path="/">
                    <Route path="login" element={<Login />} />
                    <Route path="logout" element={<Logout />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                </Route>

                <Route path="/" element={<SuperAdminInterface />} >
                    <Route index element={<Login />} />
                    <Route element={<Layout />} >
                        <Route path="dashboard" element={<SuperAdminDashboard />} />
                        <Route path="staffs" element={<SuperAdminUsers />} />
                        <Route path="staffs/sub-admin/create" element={<SuperAdminSubAdminCreate />} />
                        <Route path="staffs/sub-admin/:id/edit" element={<SuperAdminSubAdminEdit />} />
                        <Route path="staffs/interpreter/create" element={<SuperAdminInterpreterCreate />} />
                        <Route path="staffs/interpreter/:id/edit" element={<SuperAdminInterpreterEdit />} />
                        <Route path="organizations/:org_id/users/sub-admin/create" element={<SuperAdminSubAdminCreate />} />
                        <Route path="organizations/:org_id/users/sub-admin/:id/edit" element={<SuperAdminSubAdminEdit />} />
                        <Route path="organizations/:org_id/users/student/create" element={<SuperAdminStudentCreate />} />
                        <Route path="organizations/:org_id/users/student/:id/edit" element={<SuperAdminStudentEdit />} />
                        <Route path="organizations/:org_id/users/coordinator/create" element={<SuperAdminCoordinatorCreate />} />
                        <Route path="organizations/:org_id/users/coordinator/:id/edit" element={<SuperAdminCoordinatorEdit />} />
                        <Route path="organizations/:org_id/users/interpreter/create" element={<SuperAdminInterpreterCreate />} />
                        <Route path="organizations/:org_id/users/interpreter/:id/edit" element={<SuperAdminInterpreterEdit />} />
                        <Route path="profile" element={<SuperAdminProfile />} />
                        <Route path="profile/edit" element={<SuperAdminProfileEdit />} />
                        <Route path="interpretations" element={<SuperAdminInterpretation />} />
                        <Route path="assignments/:id" element={<ViewAssignment />} />
                        <Route path="assignments/:id/assign/interpreter" element={<AssignInterpreter />} />
                        <Route path="payments" element={<Payments />} />
                        <Route path="organizations" element={<Organizations />} />
                        <Route path="organizations/:id/users" element={<OrganizationUsers />} />
                        <Route path="packages" element={<Packages />} />
                        <Route path="packages/create" element={<PackageCreate />} />
                        <Route path="packages/:id/edit" element={<PackageEdit />} />
                        <Route path="support" element={<Tickets />} />
                        <Route path="support/new-ticket" element={<SupportForm />} />
                        {/* <Route path="chat" element={<SuperAdminChat/>} /> */}
                        <Route path="notifications" element={<Notification />} />
                        <Route path="chat" element={<ChatHOC />} />
                        <Route path="chat/:id" element={<ChatHOC />} />
                        <Route path="roles">
                            <Route index element={<Role />} />
                            <Route path="create" element={<RoleCreate />} />
                            <Route path=":id/edit" element={<RoleEdit />} />
                        </Route>
                        <Route path="school-type" element={<SuperAdminInterface />}>
                            <Route index element={<SchoolType />} />
                            <Route path="create" element={<SchoolCreate />} />
                            <Route path=":id/edit" element={<SchoolEdit />} />
                        </Route>
                    </Route>
                </Route>

            </Routes>
            <ToastContainer />
        </>
    )
}

export default Root;