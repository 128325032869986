import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {Col, Form, Button, Row } from "react-bootstrap";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { POST } from '../../../utils/axios.util';

import rd_trash from '../../../assets/images/icons/rd_trash.png';

function SchoolCreate(){

    const { register, handleSubmit, setError, clearErrors, setValue, getValues, formState: { errors } } = useForm();
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate()
    const [ selectedOption, setSelectedOption ] = useState('')
    const [ isDiscount, setIsDiscount ] = useState(false)
    const [ discountPrice, setDiscountPrice ] = useState(0)

    const [inputFields, setInputFields] = useState([
        { feature: '' }
    ])

    useEffect(() => {
    },[]);
  
    const previousPage = () => {
        navigate('/school-type')
    }

    const onSubmit = (data) => {

        let URL = `${BACKEND_DOMAIN}/admin/school-type/create`

        let payload = {
            url : URL,
            data
        }

        console.log(data)

        POST(payload).then(res => {

            toast.success(res.data.message)
            navigate('/school-type')

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const allowOnlyNumber=(e)=>{

        let pattern = /^\d$/

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if((key == 110 || key == 190 || key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {
            return true;
        }else {
            e.preventDefault();
        }
        
    }

    const handleFormChange = (index, event) => {
        clearErrors(`feature-${index}`)
        let featureData = [...inputFields];
        featureData[index][event.target.name] = event.target.value;
        setInputFields(featureData);
    }

    const addFields = () => {
        let newfield = { feature: '' }
        setInputFields([...inputFields, newfield])
    }

    const removeFields = (index) => {

        let featureData = [...inputFields];
        featureData.splice(index, 1)

        setInputFields(featureData)
    }

    const onDueChange = (event) => {
        setValue('discount_percentage', '')
        setIsDiscount(false)
        setSelectedOption(event.target.value)
    }
    
    const onDiscountChange = (event) => {
        setValue('discount_percentage', '')
        if(event.target.checked) {
            setIsDiscount(true)
        }else {
            setIsDiscount(false)
        }
    }

    const limitNumber = (e) => {

        let pattern = /^\d$/

        let charValue= String.fromCharCode(e.keyCode);
        let key = e.which

        if((key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || (key >= 96 && key <= 105)) || pattern.test(charValue) ) {

            let discountVal = e.target.value
            if(pattern.test(charValue) || (key >= 96 && key <= 105)) {

                discountVal = `${discountVal}${charValue}`
                discountVal = Number(discountVal.trim())

                if(discountVal >= 1 && discountVal <= 100) {
                    return true;
                }else {
                    e.preventDefault();
                }
            }
            return true;
        }else {
            e.preventDefault();
        }
    }

    const applyDiscount = (e) => {
        e.preventDefault()
        let discountValue = getValues("discount_percentage")
        let priceValue = getValues("price")

        if(priceValue && discountValue && (discountValue >= 1 && discountValue <= 100)) {
            let afterDiscount = priceValue - ( priceValue * discountValue/100 );
            setDiscountPrice(afterDiscount)
        }else {
            setDiscountPrice(0)
        }
    }

    return(
        <>
        <div className='d-flex justify-content-between'>
            <h2 className="page_hdng">Schools </h2>
            <button className='back_btn' onClick={() => previousPage()} >Back</button>
            </div>
            <Row className="mt-5 justify-content-center">
                <Col sm="5">
                    <div>
                        <h4 className='text-center'>Add School</h4>
                        <Form onSubmit={handleSubmit(onSubmit)}  >
                            <Row className="mb-3">
                                <Col sm="12">
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" defaultValue={''} onChange={(event) => setValue('name', event.target.value)} {...register('name', { required: "Name is required" })} autoComplete="off"/>

                                        {errors.name ?.message && <span className="error">{errors.name ?.message}</span> }
                                    </Form.Group>
                                </Col>

                            </Row>


                            
                            <Row className="my-2 justify-content-center">
                                <Col className='col-auto'>
                                    <button className='cancel_btn' onClick={() => previousPage()} >Cancel</button>
                                </Col>
                                <Col className='col-auto'>
                                    <button className='save_btn' type="submit" >Save</button>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                </Col>
            </Row>
        </>
    )
}

export default SchoolCreate;