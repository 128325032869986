/* import firebaseAdmin from "firebase-admin/app"
import serviceAccount from "../config/firebase-adminsdk.json"

firebaseAdmin.initializeApp({
  credential: firebaseAdmin.credential.cert(serviceAccount),
  databaseURL: process.env.FIREBASE_DATABASE
})

export default firebaseAdmin  */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTPGwm0Hi5Fit1A-u_LvzsKiUNYl3fQNQ",
  authDomain: "terp2go-1f58c.firebaseapp.com",
  databaseURL: "https://terp2go-1f58c-default-rtdb.firebaseio.com",
  projectId: "terp2go-1f58c",
  storageBucket: "terp2go-1f58c.appspot.com",
  messagingSenderId: "61353299649",
  appId: "1:61353299649:web:033d37a6115fc9f08bce40",
  measurementId: "G-JVQKNWE1HF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firebaseDb = getDatabase(app)
// const database = getDatabase(app);

export default firebaseDb