import download from 'downloadjs';
import { BACKEND_DOMAIN } from '../config/variables';
import axios from "axios";

const generatePdf = (transaction_id) => {

    let user = localStorage.getItem('user')
    let token = ''

    if(user) {
        user = JSON.parse(user)
        token = user['jwt_token']
    }

    var headerFields = {
        'Content-Type': 'application/json',
        'Api-Key': process.env.REACT_APP_BACKEND_API_KEY,
        'x-access-token': token
    }

    let data = {
        transaction_id
    }

    var dataEncoded = '';
    dataEncoded = Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');

    axios.post(`${BACKEND_DOMAIN}/generate-invoice`, 
    {
        ...data
    },
    {
        headers: headerFields,
        responseType: 'blob',
        withCredentials: true
    })      
    .then(async (res) => {
        console.log(res)

        download(res.data, 'invoice.pdf');
    })
    .catch((err) => {
        console.log(err)
    })

}

export default generatePdf