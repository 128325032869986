import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {Col, Form, Button, Row } from "react-bootstrap";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { GET, PATCH } from '../../../utils/axios.util';

import rd_trash from '../../../assets/images/icons/rd_trash.png';

function RoleCreate(){

    const { register, handleSubmit, setError, clearErrors, setValue, getValues, formState: { errors } } = useForm();
    const navigate = useNavigate()

    const params = useParams();
    const [ menus, setMenus ] = useState(null)
    const [ role, setRole ] = useState(null)
    const [ isLoading, setIsLoading ] = useState([])
    const [ selectedOptions, setSelectedOptions ] = useState({})
    var isRoleInitialized = useRef(false)

    useEffect(() => {

        if(!isRoleInitialized.current) {
            getRole()
        }

    },[ selectedOptions ]);
  
    const previousPage = () => {
        navigate('/roles')
    }

    const getRole = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/admin/roles/${params.id}`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data
                
                console.log(data)

                isRoleInitialized.current = true
                if(Object.keys(data.menus).length > 0){

                    let tempMenuArr = []
                    for(let rl in data.menus) {
                        tempMenuArr.push({
                            id: data.menus[rl]['id'],
                            name: rl
                        })
                    }

                    setMenus(tempMenuArr)
                    setRole(data)
                    setSelectedOptions(data.menus)
                }
                setIsLoading(false)
            }
        }).catch(err => {

            // Session Logout Code
            setIsLoading(false)
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
            
        })
    }

    const onSubmit = (data) => {

        let selectedValues = []

        for(let option in selectedOptions) {

            selectedValues.push(selectedOptions[option]['isSelected'])
        }

        function checkValues(val) {
            return val === false;
        }

        if(selectedValues.every(checkValues)) {
            setError(`selectedOptionError`, {
                type: "manual",
                message: "Atleast one menu must be selected!"
            });
            return;
        }

        let URL = `${BACKEND_DOMAIN}/admin/roles/${params.id}/edit`

        let payload = {
            url : URL,
            data: {
                menus: JSON.stringify(selectedOptions),
                name: data.name,
                role_id: params.id
            }
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            navigate('/roles')

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 409) {
                toast.error(err.response.data.message)
            }
        })
    }

    const onMenuChange = (event, elem) => {

        clearErrors('selectedOptionError')

        let tempObj = {...selectedOptions}

        tempObj[elem['name']] = {
            id: tempObj[elem['name']]['id'],
            isSelected: tempObj[elem['name']]['isSelected'],
            view: false,
            create: false,
            update: false,
            delete: false,
            assign_interpreter: false,
            approve_disapprove: false,
            resolve: false
        }

        if(event.target.checked) {

            tempObj[elem['name']]['isSelected'] = true

        }else {

            tempObj[elem['name']]['isSelected'] = false

        }

        setSelectedOptions(tempObj)
    }

    const onOptionChange = (event, elem, type) => {

        let tempObj = {...selectedOptions}

        if(event.target.checked) {

            tempObj[elem['name']][type] =  true

        }else {

            tempObj[elem['name']][type] =  false

        }

        setSelectedOptions(tempObj)
    }

    return(
        <>
            { (Object.keys(selectedOptions).length > 0) &&
                <>
                    <h2 className="page_hdng">Roles</h2>
                    <Row className="mt-5 justify-content-center">
                        <Col sm="12">
                            <div>
                                <h4 className='text-center'>Edit Role</h4>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="mb-3">
                                        <Col sm="6">
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" name="name" defaultValue={role.name} onChange={(event) => setValue('name', event.target.value)} {...register('name', { required: "Name is required" })} autoComplete="off" />

                                                {errors.name ?.message && <span className="error">{errors.name ?.message}</span> }
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    {   menus.map((elem, index) => {

                                            if(/interpretations/i.test(elem.name)) {

                                                return (
                                                    <React.Fragment key={elem.id}>
                                                        <Row className="mb-3">
                                                            <Col sm="6">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check className="menu-checkbox" label={elem.name} name={elem.name} type={type} id={`inline-${type}-${index}`} onChange={(event) => onMenuChange(event, elem)} checked={selectedOptions[elem.name]['isSelected']} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="View" name="view" type={type} id={`inline-${type}-${index}${index+1}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['view']} onChange={(event) => onOptionChange(event, elem, 'view')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Assign/Cancel Interpreter" name="assign_interpreter" type={type} id={`inline-${type}-${index}${index+2}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['assign_interpreter']} onChange={(event) => onOptionChange(event, elem, 'assign_interpreter')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Approve/Disapprove" name="approve_disapprove" type={type} id={`inline-${type}-${index}${index+3}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['approve_disapprove']} onChange={(event) => onOptionChange(event, elem, 'approve_disapprove')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Delete" name="delete" type={type} id={`inline-${type}-${index}${index+4}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['delete']} onChange={(event) => onOptionChange(event, elem, 'delete')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                    </React.Fragment>
                                                )

                                            }else if(/support/i.test(elem.name)) {

                                                return (
                                                    <React.Fragment key={elem.id}>
                                                        <Row className="mb-3">
                                                            <Col sm="6">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check className="menu-checkbox" label={elem.name} name={elem.name} type={type} id={`inline-${type}-${index}`} onChange={(event) => onMenuChange(event, elem)} checked={selectedOptions[elem.name]['isSelected']}  />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="View" name="view" type={type} id={`inline-${type}-${index}${index+1}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['view']} onChange={(event) => onOptionChange(event, elem, 'view')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Create" name="create" type={type} id={`inline-${type}-${index}${index+2}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['create']} onChange={(event) => onOptionChange(event, elem, 'create')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Resolve/Close" name="resolve" type={type} id={`inline-${type}-${index}${index+2}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['resolve']} onChange={(event) => onOptionChange(event, elem, 'resolve')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Delete" name="delete" type={type} id={`inline-${type}-${index}${index+4}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['delete']} onChange={(event) => onOptionChange(event, elem, 'delete')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                    </React.Fragment>
                                                )

                                            }else {

                                                return (
                                                    <React.Fragment key={elem.id}>
                                                        <Row className="mb-3">
                                                            <Col sm="6">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check className="menu-checkbox" label={elem.name} name={elem.name} type={type} id={`inline-${type}-${index}`} onChange={(event) => onMenuChange(event, elem)} checked={selectedOptions[elem.name]['isSelected']} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="View" name="view" type={type} id={`inline-${type}-${index}${index+1}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['view']} onChange={(event) => onOptionChange(event, elem, 'view')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Create" name="create" type={type} id={`inline-${type}-${index}${index+2}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['create']} onChange={(event) => onOptionChange(event, elem, 'create')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Update" name="update" type={type} id={`inline-${type}-${index}${index+3}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['update']} onChange={(event) => onOptionChange(event, elem, 'update')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                            <Col sm="3">
                                                                {['checkbox'].map((type) => (
                                                                    <div key={`inline-${type}`}>
                                                                        <Form.Check label="Delete" name="delete" type={type} id={`inline-${type}-${index}${index+4}`} disabled={!selectedOptions[elem.name]['isSelected']} checked={selectedOptions[elem.name]['delete']} onChange={(event) => onOptionChange(event, elem, 'delete')} />
                                                                    </div>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                    </React.Fragment>
                                                )
                                            }
                                        })
                                    }

                                    {errors.selectedOptionError && <span className="error">{errors.selectedOptionError.message}</span> }

                                    <Row className="my-2 justify-content-center">
                                        <Col className='col-auto'>
                                            <button className='cancel_btn' onClick={() => previousPage()} >Cancel</button>
                                        </Col>
                                        <Col className='col-auto'>
                                            <button className='save_btn' type="submit" >Save</button>
                                        </Col>
                                    </Row>
                                </Form>

                            </div>
                        </Col>
                    </Row>
                </>
            }
            { isLoading && 
                <div className='text-center subscriptions_packages_name no_records_loading'>
                    <h5>Loading...</h5>
                </div>
            }
        </>
    )
}

export default RoleCreate;