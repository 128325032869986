import React, { useEffect, useRef, useState } from 'react';
import {Modal, Col, Button,Form, Row, Tab, Nav, Table } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import member1 from '../../../assets/images/member1.png';
import grn_excla from '../../../assets/images/icons/grn_excla.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import eye from '../../../assets/images/icons/eye.svg';
import eye_slash from '../../../assets/images/icons/eye_slash.svg';
import visa_inc from '../../../assets/images/icons/visa_inc.png';
import { NavLink, useLocation } from 'react-router-dom'; 
import PaymentCard from './addNewCard';
import BankAccountForm from './addNewBank';

import { TIME_ZONE, BACKEND_DOMAIN } from '../../../config/variables';
import { GET, POST } from '../../../utils/axios.util';
import PAGINATION from '../../../components/common/pagination';
import generatePdf from '../../../utils/pdfDownload.util';

function Payments(props) {

    const location = useLocation();
    const [ card, setCard ] = useState({})
    const [ editCard, setEditCard ] = useState({})
    const [ editBank, setEditBank ] = useState({})
    const [ bankAccount, setBankAccount ] = useState({})
    const [ interpretations, setInterpretations ] = useState([])
    const [ subscriptions, setSubscriptions ] = useState([])
    const [ dues, setDues ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ modalShow, setModalShow ] = useState(false);
    const [ bankModalShow, setBankModalShow ] = useState(false);
    const [ paymentOptionModalShow, setPaymentOptionModalShow ] = useState(false);
    const handlePaymentModalClose = () => { setPaymentOptionModalShow(false) }

    const itemsPerPage = 10
    const [ duesOffset, setDuesOffset ] = useState(0);
    const [ subscriptionsOffset, setSubscriptionsOffset ] = useState(0);
    const [ interpretationsOffset, setInterpretationsOffset ] = useState(0);
    const [ currentDuesItems, setDuesItems ] = useState([])
    const [ currentSubscriptionsItems, setSubscriptionsItems ] = useState([])
    const [ currentInterpretationsItems, setInterpretationsItems ] = useState([])
    const [ duesPageCount, setDuesPageCount ] = useState(0);
    const [ subscriptionsPageCount, setSubscriptionsPageCount ] = useState(0);
    const [ interpretationsPageCount, setInterpretationsPageCount ] = useState(0);

    var isRequestSent = useRef(false)

    useEffect(() => {
        
        if(!isRequestSent.current) {
            getTransactions()
        }

    },[ subscriptions, currentDuesItems, currentSubscriptionsItems, currentInterpretationsItems, duesOffset, subscriptionsOffset, interpretationsOffset, itemsPerPage ]);

    const getTransactions = () => {

        let URL = `${BACKEND_DOMAIN}/admin/payments/list`

        setIsLoading(true)
        setModalShow(false)
        setBankModalShow(false)
        let payload = {
            url : URL
        }

        GET(payload).then(res => {
            
            const { data } = res.data

            console.log(data)

            const duesEndOffset = duesOffset + itemsPerPage;
            const subscriptionsEndOffset = subscriptionsOffset + itemsPerPage;
            const interpretationsEndOffset = interpretationsOffset + itemsPerPage;
            let duesChunk = []
            let subscriptionsChunk = []
            let interpretationsChunk = []
            let duesPageCount = Math.ceil(data.dues.length / itemsPerPage);
            let subscriptionsPageCount = Math.ceil(data.subscriptions.length / itemsPerPage);
            let interpretationsPageCount = Math.ceil(data.interpretations.length / itemsPerPage);

            if(data.dues.length > 0) {
                duesChunk = data.dues.slice(duesOffset, duesEndOffset)
            }
            if(data.subscriptions.length > 0) {
                subscriptionsChunk = data.subscriptions.slice(subscriptionsOffset, subscriptionsEndOffset)
            }
            if(data.interpretations.length > 0) {
                interpretationsChunk = data.interpretations.slice(interpretationsOffset, interpretationsEndOffset)
            }

            setDuesPageCount(duesPageCount)
            setSubscriptionsPageCount(subscriptionsPageCount)
            setInterpretationsPageCount(interpretationsPageCount)
            setDuesItems(duesChunk)
            setSubscriptionsItems(subscriptionsChunk)
            setInterpretationsItems(interpretationsChunk)

            setIsLoading(false)

            setInterpretations(data.interpretations)
            setSubscriptions(data.subscriptions)
            setDues(data.dues)
            setCard({})
            setBankAccount({})

            if(data.account) {
                if(data.account.type == 'card') {
                    setCard(data.account)
                }else {
                    setBankAccount(data.account)
                }
            }
            
            isRequestSent.current = true
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setIsLoading(false)
            console.log(err)
        })

    }

    const handlePageClick = (event, currentTab) => {

        let items = []

        if(currentTab == 'dues') {
            items = dues
        }
        if(currentTab == 'interpretations') {
            items = interpretations
        }
        if(currentTab == 'subscriptions') {
            items = subscriptions
        }
        
        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);

        if(currentTab == 'dues') {
            setDuesPageCount(pageCount)
            setDuesItems(chunk)
            setDuesOffset(newOffset);
        }
        if(currentTab == 'interpretations') {
            setInterpretationsPageCount(pageCount)
            setInterpretationsItems(chunk)
            setInterpretationsOffset(newOffset);
        }
        if(currentTab == 'subscriptions') {
            setSubscriptionsPageCount(pageCount)
            setSubscriptionsItems(chunk)
            setSubscriptionsOffset(newOffset);
        }
            
    };

    const payNow = (queue_id) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/pay-now`,
            data: {
                queue_id
            }
        }

        POST(payload).then(res => {

            toast.success(res.data.message)
            getTransactions()

        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            console.log(err)
        })
    }

    const openCardModal = (type) => {

        if(type == 'edit') {
            setEditCard(card)
        }else {
            setEditCard({})
            setPaymentOptionModalShow(false)
        }
        setModalShow(true)
    }

    const openBankModal = (type) => {

        if(type == 'edit') {
            setEditBank(bankAccount)
        }else {
            setEditBank({})
            setPaymentOptionModalShow(false)
        }
        setBankModalShow(true)
    }

    return(
        <>        

            <h2 className="page_hdng">payments</h2>
            
            <Row>
                <Col>
                    <div className="fixed_tab">
                        <Tab.Container id="" defaultActiveKey="payment_details">
                            <Row>
                                <Col className='col-auto'>
                                    <Nav variant="fixed_pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="payment_details">Manage Accounts</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="subscriptions_payments">Incoming Payments</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="interpretation">Interpretation Transactions Owed</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>

                            <Tab.Content>
                                <Tab.Pane eventKey="payment_details">
                                   {/*  { !isLoading && (Object.keys(card).length === 0) && 
                                        <div className='text-end'>
                                            <Button variant='success' size='lg' className='mt-3' onClick={() => setModalShow(true)} >Add Card</Button>
                                        </div>
                                    } */}
                                    { !isLoading && (Object.keys(card).length === 0 && Object.keys(bankAccount).length === 0 ) && (dues.length === 0) && 
                                        <Row>
                                            <Col sm="12" lg="12">
                                                <div className="chapter_list_card mt-3 text-center">
                                                    No Records Found!
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    { isLoading && 
                                        <div className='text-center subscriptions_packages_name no_records_loading'>
                                            <h5>Loading...</h5>
                                        </div>
                                    }
                                    { (Object.keys(card).length > 0) && 
                                        <>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-4'>
                                                    <h5 className='text_white_color_half fs_14 fw_500'>Payment Method</h5>
                                                    <span>
                                                        <img src={visa_inc} />
                                                    </span>
                                                    <span className='fs_14 fw_500 ms-2'>Ending in {card.ending_no}</span>
                                                </div>
                                                <div className='me-4'>
                                                    <button className='change_card_btn' onClick={() => openCardModal('edit')}>Edit Card</button>
                                                </div>
                                                <div className='me-4'>
                                                    <h5 className='text_white_color_half fs_14 fw_500'>Currency</h5>
                                                    <span className='fs_14 fw_500 ms-2'>( $ ) Dollar</span>
                                                </div>
                                                <div className='me-4'>
                                                    <h5 className='text_white_color_half fs_14 fw_500'>Threshold Limit</h5>
                                                    <span className='fs_14 fw_500 ms-2'>${card.threshold_limit}</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    { (Object.keys(bankAccount).length > 0) && 
                                        <>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-4'>
                                                    <h5 className='text_white_color_half fs_14 fw_500'>Bank Account</h5>
                                                    <span className='fs_14 fw_500 ms-2'>{bankAccount.accnt_name}</span>
                                                </div>
                                                <div className='me-4'>
                                                    <button className='change_card_btn' onClick={() => openBankModal('edit')}>Edit Bank</button>
                                                </div>
                                                <div className='me-4'>
                                                    <h5 className='text_white_color_half fs_14 fw_500'>Currency</h5>
                                                    <span className='fs_14 fw_500 ms-2'>( $ ) Dollar</span>
                                                </div>
                                                <div className='me-4'>
                                                    <h5 className='text_white_color_half fs_14 fw_500'>Account Number</h5>
                                                    <span className='fs_14 fw_500 ms-2'>{bankAccount.accnt_number}</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <NavLink className="btn btn-success btn-md px-4 my-1" onClick={() => setPaymentOptionModalShow(true)} >Add New</NavLink>
                                    { (dues.length > 0) &&
                                        currentDuesItems.map((elem, index) => {
                                            return (
                                                <span key={elem.id}>
                                                    <hr/>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <h4 className='fs-24 fw-500 text-capitalize'>Payment due</h4>
                                                        <h4 className='fs-24 fw-500 text-capitalize text_brand_color6'>overdue</h4>
                                                    </div>

                                                    <div className='my_card_brand_dark_color mt-3'>
                                                        <Table className='payment_due_table'>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='text_white_color_half'>Reference No</td>
                                                                    <td>{elem.reference_no}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text_white_color_half'>Overdue</td>
                                                                    <td>{elem.expected_date}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text_white_color_half'>Service type</td>
                                                                    <td>Interpretation</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2}><hr/></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text_white_color_half'>Hours</td>
                                                                    <td>{elem.working_hours}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2}><hr/></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text_white_color_half'>Total amount</td>
                                                                    <td className='text_brand_color2 fw_700'>${elem.salary}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className='text-end'>
                                                        <Button variant='success' size='lg' className='mt-3' onClick={() => payNow(elem.id)}>Pay Now</Button>
                                                    </div>
                                                </span>
                                            )
                                        })
                                    }
                                    { dues.length > 0 &&
                                        <PAGINATION allItems={dues} chunkedItems={currentDuesItems} handlePageClick={event => handlePageClick(event, 'dues')} pageCount={duesPageCount} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="subscriptions_payments">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th>Subscription Type</th>
                                                <th className='text-center'>Transaction id</th>
                                                <th className='text-center'>Date & Time</th>
                                                <th className='text-center'>Payment Status</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { !isLoading && (subscriptions.length === 0) &&
                                                <tr className="text-center">
                                                    <td colSpan="6" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                            { (subscriptions.length > 0) &&
                                                currentSubscriptionsItems.map((elem, index) => {
                                                    return (
                                                        <React.Fragment key={elem.id}>
                                                            { elem.is_expire && 
                                                                <tr>
                                                                    <td colSpan={2} className='text-capitalize'>{elem.name} Subscription</td>
                                                                    <td className='text-center'>{elem.expiry_date}</td>
                                                                    <td></td>
                                                                    <td className='text-end'>
                                                                        <button className='pay_now_btn'>Pay Now</button>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            { !elem.is_expire &&
                                                                <tr>
                                                                    <td className='text-capitalize'>{elem.name} Subscription Renewed</td>
                                                                    <td className='text-center'>{elem.reference_id}</td>
                                                                    <td className='text-center'>{elem.paid_date} at <span className='text_brand_color2'>{elem.paid_time}</span></td>
                                                                    <td className='text-center'>Paid</td>
                                                                    { elem.transaction_id && elem.status == 'success' &&
                                                                        <td className='text-end'>
                                                                            <NavLink className="text_white_color" onClick={() => generatePdf(elem.transaction_id)}>View invoice</NavLink>
                                                                        </td>
                                                                    }
                                                                    { !elem.transaction_id || elem.status != 'success' &&
                                                                        <td className='text-center'>--</td>
                                                                    }
                                                                </tr>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    { subscriptions.length > 0 &&
                                        <PAGINATION allItems={subscriptions} chunkedItems={currentSubscriptionsItems} handlePageClick={event => handlePageClick(event, 'subscriptions')} pageCount={subscriptionsPageCount} />
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="interpretation">
                                    <Table className="">
                                        <thead>
                                            <tr>
                                                <th className='text-center'>Transaction id</th>
                                                <th className='text-center'>Hours</th>
                                                <th className='text-center'>Amount</th>
                                                <th className='text-center'>Date & Time</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { !isLoading && (interpretations.length === 0) &&
                                            <tr className="text-center">
                                                <td colSpan="6" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                        { (interpretations.length > 0) &&
                                            currentInterpretationsItems.map((elem, index) => {
                                                return (
                                                    <React.Fragment key={elem.id}>
                                                        { elem.is_expire && 
                                                            <tr>
                                                                <td className='text-center'>--</td>
                                                                <td className='text-center'>{elem.working_hours}</td>
                                                                <td className='text-center'>${elem.salary}</td>
                                                                <td className='text-center'>{elem.expected_date}</td>
                                                                <td className='text-end'>
                                                                    <button className='pay_now_btn' onClick={() => payNow(elem.id)}>Pay Now</button>
                                                                </td>
                                                            </tr>
                                                        }
                                                        { !elem.is_expire && 
                                                            <tr>
                                                                <td className='text-center'>{elem.reference_id}</td>
                                                                <td className='text-center'>{elem.working_hours}</td>
                                                                <td className='text-center'>${elem.salary}</td>
                                                                <td className='text-center'>{elem.paid_date} at  <span className='text_brand_color2'>{elem.paid_time}</span></td>
                                                                <td className='text-end'>
                                                                    <NavLink className="text_white_color" onClick={() => generatePdf(elem.id)}>View invoice</NavLink>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                    { interpretations.length > 0 &&
                                        <PAGINATION allItems={interpretations} chunkedItems={currentInterpretationsItems} handlePageClick={event => handlePageClick(event, 'interpretations')} pageCount={interpretationsPageCount} />
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>
            </Row>
            <PaymentCard show={modalShow} cardDetails={editCard} getTransactions={getTransactions} onHide={() => {setEditCard({}); setModalShow(false)}} />
            <BankAccountForm show={bankModalShow} bankDetails={editBank} getTransactions={getTransactions} onHide={() => {setEditBank({}); setBankModalShow(false)}} />

            <Modal backdrop="static" className="approve-modal" show={paymentOptionModalShow} onHide={handlePaymentModalClose} keyboard={false} centered>
                <Modal.Header className='justify-content-center' closeButton >
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Choose the payment method.</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="primary" onClick={() => openCardModal('create') } >Card</Button>
                        <Button variant="primary" onClick={() => openBankModal('create')} >Bank</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Payments;