import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Pagination } from "react-bootstrap";

import play_icon from '../../assets/images/icons/play.svg';

function PAGINATION(props) {

    const currentItems = props.chunkedItems
    const allItems = props.allItems
    const handlePageClick = props.handlePageClick
    const pageCount = props.pageCount
    let currentPageNum = props.currentPageNumber
    let initialPageNum = props.initialPageNumber

    useEffect(() => {

    },[ props.chunkedItems, props.allItems, props.pageCount ]);

    return(
        
        <>
            { currentItems.length > 0 &&
                <ReactPaginate 
                    className="my-3 justify-content-center pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="active"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    breakLabel="..."
                    initialPage={initialPageNum}
                    forcePage={currentPageNum}
                    nextLabel={currentItems[currentItems.length-1]['id'] === allItems[allItems.length-1]['id'] ? null : '>'}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={currentItems[0]['id'] === allItems[0]['id'] ? null : '<'}
                    renderOnZeroPageCount={null}
                />
               /*  <Pagination className='my-3 justify-content-center'>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>

                    <Pagination.Ellipsis />

                    <Pagination.Item>{10}</Pagination.Item>
                    <Pagination.Item>{11}</Pagination.Item>
                    <Pagination.Item active>{12}</Pagination.Item>
                    <Pagination.Item>{13}</Pagination.Item>
                    <Pagination.Item disabled>{14}</Pagination.Item>

                    <Pagination.Ellipsis />

                    <Pagination.Item>{20}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                </Pagination> */
            }
        </>
    )
}

export default PAGINATION;