import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../config/variables';
import { GET } from '../../utils/axios.util';

const Logout = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let payload = {
            url : `${BACKEND_DOMAIN}/logout`
        }
        GET(payload).then(res => {

            let user = localStorage.getItem('user')

            if(user) {
                localStorage.removeItem('user')
            }

            navigate('/login')
            
        }).catch(err => {
 
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    navigate('/login')
                }, 1000)
            }
            

            console.log('Error during logout :(')
            console.log(err)
        })
    
    },[]);
}

export default Logout;