import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../config/variables';
import { GET } from '../utils/axios.util';

const useAssignmentsQueue = () => {

    const [ assignments, setAssignments ] = useState({});

    useEffect(() => {

        let payload = {
            url : `${BACKEND_DOMAIN}/assignments/queue`
        }
        
        GET(payload).then(res => {
            console.log(res);
            
            const { data } = res.data
            setAssignments(data)
        }).catch(err => {

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            setAssignments({})
        })

    }, [])


    return assignments;

}

export default useAssignmentsQueue