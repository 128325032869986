const initialState = {
    file_path: '',
    title: '',
    category: '',
    sub_category: ''
}

const audioReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'OPEN':
            return {
                file_path: action.payload.file_path,
                title: action.payload.title,
                category: action.payload.category,
                sub_category: action.payload.sub_category
            }
        case 'CLOSE':
            return {
                file_path: '',
                title: '',
                category: '',
                sub_category: ''
            }
        default: return state
    }
}

export default audioReducer