import React, { useEffect, useState, useRef } from 'react';
import {Col, Button,Row,Tab,Nav, Badge } from "react-bootstrap";
import left_arrow from '../../assets/images/icons/left_arrow.png';
import right_arrow from '../../assets/images/icons/right_arrow.png';
import member1 from '../../assets/images/member1.png';
import member2 from '../../assets/images/member2.png';
import member3 from '../../assets/images/member3.png';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BACKEND_DOMAIN } from '../../config/variables';
import { GET } from '../../utils/axios.util';

function Notification(props) {

    const user = props.user

    const [ notifications, setNotifications ] = useState({})
    const [ assignmentNotifications, setAssignmentNotifications ] = useState([])
    const [ generalNotifications, setGeneralNotifications ] = useState([])
    const [ paymentNotifications, setPaymentNotifications ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isNoRecords, setIsNoRecords ] = useState(false) 

    var isNotificationsInitialized = useRef(false) 

    useEffect(() => {

        if(!isNotificationsInitialized.current) {
            getNotifications()
        }

    },[ notifications ]);

    const getNotifications = () => {

        setIsLoading(true)
        let payload = {
            url : `${BACKEND_DOMAIN}/notifications/list`
        }

        GET(payload).then(res => {
            if(res.data) { 
                const { data } = res.data

                isNotificationsInitialized.current = true
                setNotifications(data);
                setAssignmentNotifications(data.assignments);
                setGeneralNotifications(data.general);
                setPaymentNotifications(data.payments);
                setIsLoading(false)
                setIsNoRecords(false)
            }
        }).catch(err => {
            console.log(err)

            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
            
                setIsLoading(false)
                setIsNoRecords(true)
        })
    }

    return(
        <>        

            <h2 className="page_hdng">Notifications</h2>
            <div className='my_card mt-4'>
                <Row>
                    <Col>
                    <div className="my_card">
                        <Tab.Container id="fdgdfg" defaultActiveKey="general">
                            <Row className='align-items-center justify-content-between'>
                                <Col className='col-auto'>
                                    <Nav className="my_pill_tab_nav">
                                        <Nav.Item>
                                            <Nav.Link eventKey="general">General</Nav.Link>
                                        </Nav.Item>
                                        { user.role == 'admin' || user.role == 'super_admin' && 
                                            <Nav.Item>
                                                <Nav.Link eventKey="payment">Payment</Nav.Link>
                                            </Nav.Item>
                                        }
                                    </Nav>
                                </Col>
                                <Col className='col-auto'>
                                    
                               {/*  <div className="assignments_date">
                                    <Button variant='light'>
                                    <button>
                                        <img src={left_arrow} />
                                    </button>
                                    <span className="assign_date">July 22 to 29</span>
                                    <button>
                                        <img src={right_arrow} />
                                    </button>
                                    </Button>
                                </div> */}
                                </Col>
                            </Row>

                            <Tab.Content className='p-0 mt-3'>
                                <Tab.Pane eventKey="general">
                                    <div className="mt-3">

                                        { !isLoading && (generalNotifications.length === 0 || isNoRecords) && 
                                            <Row>
                                                <Col sm="12" lg="12">
                                                    <div className="chapter_list_card mt-3 text-center">
                                                        No Records Found!
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        { isLoading && !isNoRecords &&
                                            <div className='text-center subscriptions_packages_name no_records_loading'>
                                                <h5>Loading...</h5>
                                            </div>
                                        }
                                        { generalNotifications.length > 0 && generalNotifications.map((elem, index) => {

                                            return (
                                                <>
                                                    <div key={elem.id}>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <p className='fs-16 fw-600 m-0'>{elem.full_name}</p>
                                                            {/* <Badge bg="brand_color3 " className='text_white_color'>New</Badge> */}
                                                        </div>
                                                        <div className='my-2'>
                                                            <p className='fs-14 fw-500 text_white_opacity7 m-0'>{elem.message}</p>
                                                        </div>
                                                        <Row className='justify-content-between align-items-center'>
                                                            <Col className='col-auto'>
                                                                <p className='fs-12 fw-700 text_brand_color2 m-0'>
                                                                <Moment format="DD/MM/YY hh:mm A">
                                                                    {elem.created_at}
                                                                </Moment></p>
                                                            </Col>
                                                            {/* <Col className='col-auto'>
                                                                <div className='tbl_assigned_users_img'>
                                                                    <img className='tbl_usr_img' src={member3} />
                                                                    <span>20+</span>
                                                                </div>
                                                            </Col> */}
                                                        </Row>
                                                    </div>
                                                    <hr/>
                                                </>
                                            )
                                            })
                                        }

                                    {/* <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Abubakr Dampha</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_white_opacity7 m-0'>Today, 2:00 PM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member1} />
                                                        <img className='tbl_usr_img' src={member2} />
                                                        <img className='tbl_usr_img' src={member3} />
                                                        <span>20+</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <hr/>
                                    
                                    <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Adam Khalil</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_white_opacity7 m-0'>Today, 2:00 PM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member1} />
                                                        <img className='tbl_usr_img' src={member2} />
                                                        <img className='tbl_usr_img' src={member3} />
                                                        <span>20+</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Abubakr Dampha</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_white_opacity7 m-0'>Today, 2:00 PM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member1} />
                                                        <img className='tbl_usr_img' src={member2} />
                                                        <img className='tbl_usr_img' src={member3} />
                                                        <span>20+</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <hr/>

                                    <div className="mt-3">
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <p className='fs-16 fw-600 m-0'>Adam Khalil</p>
                                                <Badge bg="brand_color3 " className='text_white_color'>New</Badge>
                                            </div>
                                            <div className='my-2'>
                                                <p className='fs-14 fw-500 text_white_opacity7 m-0'>Lore Issue is simply dummy text of the printing and typesetting industry. Lore Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of </p>
                                            </div>
                                            <Row className='justify-content-between align-items-center'>
                                                <Col className='col-auto'>
                                                    <p className='fs-12 fw-700 text_white_opacity7 m-0'>Today, 2:00 PM</p>
                                                </Col>
                                                <Col className='col-auto'>
                                                    <div className='tbl_assigned_users_img'>
                                                        <img className='tbl_usr_img' src={member1} />
                                                        <img className='tbl_usr_img' src={member2} />
                                                        <img className='tbl_usr_img' src={member3} />
                                                        <span>20+</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div> */}

                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="payment">
                                    <div className="mt-3">

                                    { !isLoading && (paymentNotifications.length === 0 || isNoRecords) && 
                                        <Row>
                                            <Col sm="12" lg="12">
                                                <div className="chapter_list_card mt-3 text-center">
                                                    No Records Found!
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    { isLoading && !isNoRecords &&
                                        <div className='text-center subscriptions_packages_name no_records_loading'>
                                            <h5>Loading...</h5>
                                        </div>
                                    }
                                    { paymentNotifications.length > 0 && paymentNotifications.map((elem, index) => {

                                        return (
                                            <>
                                                <div key={elem.id}>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <p className='fs-16 fw-600 m-0'>{elem.full_name}</p>
                                                        {/* <Badge bg="brand_color3 " className='text_white_color'>New</Badge> */}
                                                    </div>
                                                    <div className='my-2'>
                                                        <p className='fs-14 fw-500 text_white_opacity7 m-0'>{elem.message}</p>
                                                    </div>
                                                    <Row className='justify-content-between align-items-center'>
                                                        <Col className='col-auto'>
                                                            <p className='fs-12 fw-700 text_brand_color2 m-0'>
                                                            <Moment format="DD/MM/YY hh:mm A">
                                                                {elem.created_at}
                                                            </Moment></p>
                                                        </Col>
                                                        {/* <Col className='col-auto'>
                                                            <div className='tbl_assigned_users_img'>
                                                                <img className='tbl_usr_img' src={member3} />
                                                                <span>20+</span>
                                                            </div>
                                                        </Col> */}
                                                    </Row>
                                                </div>
                                                <hr/>
                                            </>
                                        )
                                        })
                                    }
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.authenticate,
        chat: state.chat
    }
}

export default connect(mapStateToProps, null)(Notification);